import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editAsset, getAsset } from "../../../api/assets"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"

import useAllowedFields from "../../../hooks/useAllowedFields"
import CustomForm from "../../../components/CustomForm"
import FIELDS, {AQUSITION_FIELDS} from "./CONSTANT"
import AssetForm from "./AssetForm"

const EditAsset = () => {
    let navigate = useHistory()
    const { assetSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedAsset, setEditedAsset] = useState(null)
    const getAllowedFields = useAllowedFields('Asset')

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getAsset,
        params.id,
        response => {
            setEditedAsset({
                ...response.data.data.asset,
                // Presenters: response.data.data.asset.Presenters.map(p => {
                //     return { value: p.id, label: p.name }
                // }),
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editAsset, () => {
        navigate.push("/assets")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            broadcasts: Array(editedAsset?.numOfActualBroadcasts).fill(AQUSITION_FIELDS.find(f => f.key == 'broadcasts').schema),

            ...editedAsset,
            nameEn: editedAsset?.name?.en,
            nameAr: editedAsset?.name?.ar,
            // slug: editedAsset ? editedAsset.slug : "",
            // summary: editedAsset ? editedAsset.summary : "",
            // img: editedAsset ? editedAsset.image_url : "",
            // presenters: editedAsset ? editedAsset.Presenters : null,
            // cover_url: editedAsset ? editedAsset.cover_url : "",
            // order: editedAsset ? editedAsset.order : 0
        },
        validationSchema: assetSchema,
        onSubmit: values => {
            let data = {
                ...values,
                name: {
                    en: values.nameEn,
                    ar: values.nameAr
                }
            }
            delete data.nameEn
            delete data.nameAr
            delete data.id

            Object.keys(data).forEach(key => {
                const haveWritePerm = getAllowedFields().find(fi => fi.name == key)?.RoleField?.privileges?.includes('write')
                if(!haveWritePerm){
                    delete data[key]
                }
            })
            setData({ type: "edit", id: params.id, data })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Assets")}
                        breadcrumbItem={t("btns.Edit") + " " + t("pages.Asset")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedAsset && (
                                        <Form
                                            className="needs-validation"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <AssetForm validation={validation} />

                                            <FormButtonHandler btnTxt="Edit" loading={loading} />
                                        </Form>


                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditAsset

import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editEpisode, getEpisode } from "../../../api/episodes"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"

import useAllowedFields from "../../../hooks/useAllowedFields"
import CustomForm from "../../../components/CustomForm"
import FIELDS from "./CONSTANT"

const EditEpisode = () => {
    let navigate = useHistory()
    const { episodeSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedEpisode, setEditedEpisode] = useState(null)
    const getAllowedFields = useAllowedFields('Episode')

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getEpisode,
        params.id,
        response => {
            setEditedEpisode({
                ...response.data.data.episode,
                // Presenters: response.data.data.episode.Presenters.map(p => {
                //     return { value: p.id, label: p.name }
                // }),
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editEpisode, () => {
        // navigate back
        navigate.goBack();
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            ...editedEpisode,
            // slug: editedEpisode ? editedEpisode.slug : "",
            // summary: editedEpisode ? editedEpisode.summary : "",
            // img: editedEpisode ? editedEpisode.image_url : "",
            // presenters: editedEpisode ? editedEpisode.Presenters : null,
            // cover_url: editedEpisode ? editedEpisode.cover_url : "",
            // order: editedEpisode ? editedEpisode.order : 0
        },
        validationSchema: episodeSchema,
        onSubmit: values => {
            let data = {
                ...values,
            }
            delete data.id

            Object.keys(data).forEach(key => {
                const haveWritePerm = getAllowedFields().find(fi => fi.name == key)?.RoleField?.privileges?.includes('write')
                if(!haveWritePerm){
                    delete data[key]
                }
            })
            setData({ type: "edit", id: params.id, data })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Episodes")}
                        breadcrumbItem={t("btns.Edit") + " " + t("pages.Episode")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedEpisode && (
                                        <Form
                                            className="needs-validation"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <CustomForm model="Episode" fields={FIELDS} validation={validation} />

                                            <FormButtonHandler btnTxt="Edit" loading={loading} />
                                        </Form>
                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditEpisode

import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import { deleteSupplier, getSuppliers, publishSupplier, importSuppliers } from "../../../api/suppliers"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "reactstrap"
import { PERMISSIONS } from "../../../api/permissions";
import ImportModal from "../../../components/Common/ImportModal"

export default function SuppliersTable() {

  const { t } = useTranslation()


  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getSuppliers)

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingSupplier, setDeletingSupplier] = useState(null)

  const { setData } = useResponse(deleteSupplier, () => {
    setShow(false)
    setDeletingSupplier(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingSupplier })
  }
  // import 
  const [showImport, setImport] = useState(false)

  function onCloseImportClick() {
    setImport(false)
  }
  //// publish 
  const [publishShow, setPublishShow] = useState(false)

  function onClosePublish() {
    setPublishShow(false)
  }

  const { setData: setPublishSupplier } = useResponse(publishSupplier, () => {
    setPublishShow(false)
    setDeletingSupplier(null)
    setPayload({})
  })

  function onPublishClick() {
    setPublishSupplier({ type: "add", data: deletingSupplier })
  }
  const navigate = useHistory().push

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.SUPPLIER_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-supplier"
      >
        <Button onClick={() => {
          setImport(true)
        }} color="secondary" className="btn-add mx-2">
          {t("tables.Import")}
        </Button>
      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                {/*<Th style={{ width: "20%" }}>{t("forms.Image")}</Th>*/}
                <Th style={{ width: "20%" }}>ID</Th>
                <Th style={{ width: "50%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.suppliers.map(row => {
                  return (
                    <Tr key={row.id}>
                      {/*<Td>*/}
                      {/*  <img src={row.image_url} width="40px" height="40px" />*/}
                      {/*</Td>*/}
                      <Td>{row.id}</Td>
                      <Td><a href={""} onClick={(e)=> {
                        e.preventDefault()
                        navigate("/supplier/" + row.id + '/assets', {supplierName: row.name})
                      }}>{row.name}</a></Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.SUPPLIER_EDIT}
                          deletePermission={PERMISSIONS.SUPPLIER_DELETE}
                          type="supplier"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingSupplier}
                        />
                        {/*<Button onClick={() => {*/}
                        {/*  setPublishShow(true)*/}
                        {/*  setDeletingSupplier(row.id)*/}
                        {/*}}>*/}
                        {/*    {row.is_published ? t("btns.Unpublish") : t("btns.Publish")}*/}
                        {/*</Button>*/}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.suppliers.length === 0} msg="No Suppliers Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          <DeleteModal
            show={publishShow}
            onDeleteClick={onPublishClick}
            onCloseClick={onClosePublish}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ImportModal show={showImport} onCloseClick={onCloseImportClick} api={importSuppliers} fileName='import-suppliers.xlsx' listName='suppliers'></ImportModal>

          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

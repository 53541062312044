import { myAxios } from "./axios"

export function getAudits(payload, { page, perPage, action, username, email, assetName }) {
    let config = {
        params: {
            page,
            perPage,
            action: payload ? payload.action : null,
            username: payload ? payload.username : null,
            email: payload ? payload.email : null,
            assetName: payload ? payload.assetName : null,
        },
    }
    return myAxios.get("/audits", config)
}

export function revertAudit({ id }) {
    return myAxios.post(`/audits/${id}/revert`)
}
import React from "react"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/QN-logo-mini.png"
import logoDark from "../../assets/images/QN-logo.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div
          className="navbar-brand-box"
          style={{ padding: "0 1.1rem", backgroundColor: "white" }}
        >
          <Link to="/assets" className="logo logo-dark">
            <span className="logo-sm" style={{}}>
              <img src={logo} alt="" height="45" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="45" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar

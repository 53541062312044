import React, { useEffect } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {useHistory, useParams} from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { addEpisode } from "../../../api/episodes"
import ChooseImage from "../../../components/ChooseImage"
import FormButtonHandler from "../../../components/FormButtonHandler"
import SearchSelectBox from "../../../components/SearchSelectBox"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import FIELDS from "./CONSTANT"

import useAllowedFields from "../../../hooks/useAllowedFields"
import CustomForm from "../../../components/CustomForm"

const TYPES = {
    'select': null,
    'add-more': [],
    'checkbox': false
}
const initialValues = {}

FIELDS.forEach(f => {
    initialValues[f.key] = TYPES[f.type] ? TYPES[f.type] : "" 
})



const AddEpisodes = () => {
    const { assetId} = useParams();
    initialValues['assetId'] = parseInt(assetId)
    console.log(initialValues, 'initialValues');

    const { t } = useTranslation()
    let navigate = useHistory()
    const { episodeSchema } = useCustomValidation()
    const { setData, loading, error, success } = useResponse(addEpisode, () => {
        navigate.push("/episodes")
    })
    const getAllowedFields = useAllowedFields('Episode')


    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            let data = {
                ...values,
                
            }
            // Object.keys(data).forEach(key => {
            //     const haveWritePerm = getAllowedFields().find(fi => fi.name == key)?.RoleField?.privileges?.includes('write')
            //     if(!haveWritePerm){
            //         delete data[key]
            //     }
            // })
            setData({ data, type: "add" })
        },
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Episodes")}
                        breadcrumbItem={t("btns.Add") + " " + t("pages.Episode")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <CustomForm model="Episode" fields={FIELDS} validation={validation}  allowedFields={false}/>

                                        <FormButtonHandler btnTxt="Add" loading={loading} />

                                        <ResultHandler
                                            error={error}
                                            success={success}
                                            successMsg={"Created Successfully!"}
                                        />
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddEpisodes

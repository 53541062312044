import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editLanguage, getLanguage } from "../../../api/languages"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import LanguageForm from "./LanguageForm"

const EditLanguage = () => {
    let navigate = useHistory()
    const { languageSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedLanguage, setEditedLanguage] = useState(null)

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getLanguage,
        params.id,
        response => {
            setEditedLanguage({
                ...response.data.data.language,
                // Presenters: response.data.data.language.Presenters.map(p => {
                //     return { value: p.id, label: p.name }
                // }),
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editLanguage, () => {
        navigate.push("/languages")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: editedLanguage ? editedLanguage.name : "",
            //code: editedLanguage ? editedLanguage.code : "",
            // slug: editedLanguage ? editedLanguage.slug : "",
            // summary: editedLanguage ? editedLanguage.summary : "",
            // img: editedLanguage ? editedLanguage.image_url : "",
            // presenters: editedLanguage ? editedLanguage.Presenters : null,
            // cover_url: editedLanguage ? editedLanguage.cover_url : "",
            // order: editedLanguage ? editedLanguage.order : 0
        },
        validationSchema: languageSchema,
        onSubmit: values => {
            let data = {
                ...values,
                // image_url: values.img,
                // Presenters: values.presenters.map(p => {
                //     return p.value
                // }),
            }
            setData({ type: "edit", id: params.id, data })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Languages")}
                        breadcrumbItem={t("btns.Edit") + " " + t("pages.Language")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedLanguage && (
                                        <Form
                                            className="needs-validation"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <LanguageForm validation={validation} />

                                            <FormButtonHandler btnTxt="Edit" loading={loading} />
                                        </Form>
                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditLanguage
import { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form, Table, Button, ButtonGroup, Accordion, AccordionHeader, AccordionBody, AccordionItem,
} from "reactstrap"

function camelToHuman(input) {
    // Insert a space before all uppercase letters except the first one
    return input.replace(/([A-Z])/g, ' $1')
        // Capitalize the first letter and remove any leading spaces
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
}

function parseFieldName(input) {
    let s = camelToHuman(input)
    let isId = (s.split(' ').slice(-1)[0] === "Id")
    if (isId) {
        s = s.split(' ')
        s[s.length - 1] = " Dropdown"
        return s
    } else return s
}


export default function ModelFields({ model, validation }) {
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <Accordion toggle={toggle} open={open}>

            <AccordionItem className="mb-4">
                <AccordionHeader targetId="1">
                    {model.name}

                </AccordionHeader >
                <AccordionBody accordionId="1">
                    {model?.fields?.map(f => {
                        const field = validation.values?.fields.find((o) => o.id === f.id);
                        let privileges = field?.privileges || [];
                        const isCheckedWrite = privileges?.includes('write');
                        const isCheckedRead = privileges?.includes('read');

                        return <div className="d-flex mb-2" key={f.id} style={{ justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontWeight: "500" }}>{parseFieldName(f.name)}</div>
                            <div className="d-flex">

                                <Button
                                    style={{ margin: "0px 10px" }}
                                    color="primary"
                                    outline={!isCheckedRead}
                                    onClick={() => {
                                        // if field exists in the array, remove it
                                        if (field) {
                                            validation.setFieldValue('fields', [...validation.values.fields.filter(o => o.id != f.id)])
                                        } else {
                                            validation.setFieldValue('fields', [...validation.values.fields, { id: f.id, privileges: (privileges.includes('read') ? privileges : [...privileges, 'read']) }]);
                                        }
                                    }}
                                >
                                    {isCheckedRead && <i className="bx bx-check" style={{ fontSize: "16px" }}></i>}
                                    Read
                                </Button>
                                <Button
                                    color="primary"
                                    outline={!isCheckedWrite}
                                    onClick={() => {
                                        if (field) {
                                            validation.setFieldValue('fields', [...validation.values.fields.filter(o => o.id !== f.id), { id: f.id, privileges: (privileges.includes('write') ? privileges.filter(p => p != 'write') : ['read', 'write']) }])
                                        } else {
                                            validation.setFieldValue('fields', [...validation.values.fields, { id: f.id, privileges: ['write', 'read'] }]);
                                        }
                                    }}
                                    h
                                >
                                    {isCheckedWrite && <i className="bx bx-check" style={{ fontSize: "16px" }}></i>}
                                    Write
                                </Button>
                            </div>
                        </div>
                    })}
                </AccordionBody>
            </AccordionItem>

        </Accordion>
    )
}
import { myAxios } from "./axios"

export function getGenres(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            title: data ? data.txt : null,
        },
    }
    return myAxios.get("/genres", config)
}


export function getGenre(id) {
    return myAxios.get("/genres/" + id)
}

export function addGenre(data) {
    return myAxios.post("/genres/create", data)
}

export function editGenre(id, data) {
    return myAxios.put("/genres/" + id + "/update", data)
}

export function deleteGenre(id) {
    return myAxios.delete("/genres/" + id + "/delete")
}

export function publishGenre(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/genres/" + id + "/publish")
    } else {
        return myAxios.post("/genres/" + id + "/unpublish")
    }
}

export function importGenres(data) {
    return myAxios.post("/genres/import", data)
}
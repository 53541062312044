

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"
import SearchSelectBox from "../../../components/SearchSelectBox"
import SlugInput from "../../../components/SlugInput"
import ChooseImage from "../../../components/ChooseImage"
import { useTranslation } from "react-i18next"

export default function CategoryForm({validation}){
    const { t } = useTranslation()

    return(

        <Row>
            <Col md={6}>
                <FormGroup className="mb-3">
                    <Label>{t("forms.NameEn")}</Label>
                    <Input
                        name="name_en"
                        placeholder={t("forms.NameEn")}
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name_en || ""}
                        invalid={
                            validation.touched.name_en &&
                            validation.errors.name_en
                                ? true
                                : false
                        }
                    />
                    {validation.touched.name_en &&
                    validation.errors.name_en ? (
                        <FormFeedback type="invalid">
                            {validation.errors.name_en}
                        </FormFeedback>
                    ) : null}
                </FormGroup>

                <FormGroup className="mb-3">
                    <Label>{t("forms.NameAr")}</Label>
                    <Input
                        name="name_ar"
                        placeholder={t("forms.NameAr")}
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name_ar || ""}
                        invalid={
                            validation.touched.name_ar &&
                            validation.errors.name_ar
                                ? true
                                : false
                        }
                    />
                    {validation.touched.name_ar &&
                    validation.errors.name_ar ? (
                        <FormFeedback type="invalid">
                            {validation.errors.name_ar}
                        </FormFeedback>
                    ) : null}
                </FormGroup>

            </Col>


        </Row>

    )
}
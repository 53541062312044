import { myAxios } from "./axios"

export function getAssets(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            channelId: data?.channelId,
            supplierId: data?.supplierId,
            categoryId: data?.categoryId,
            genreId: data?.genreId,
            languageId: data?.languageId,
            expirationDate: data?.expirationDate,
            filter: data

        },
    }
    return myAxios.get("/assets", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/assets/export", config)
}

export function getAsset(id) {
    return myAxios.get("/assets/" + id)
}

export function addAsset(data) {
    return myAxios.post("/assets/create", data)
}

export function editAsset(id, data) {
    return myAxios.put("/assets/" + id + "/update", data)
}

export function deleteAsset(id) {
    return myAxios.delete("/assets/" + id + "/delete")
}
export function deleteAllAssets(data) {
    console.log("sdf",data);
    return myAxios.post("/assets/deleteAll/"+data)
}
export function importFile(data) {
    return myAxios.post("/assets/import", data)
}

import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import AssetsTable from "./AssetsTable"
import { useTranslation } from "react-i18next"
import {useHistory, useParams} from "react-router-dom";

export default function AssetsList() {

  const { t } = useTranslation()
    const params = useHistory()
  const { channelId,supplierId ,categoryId,languageId,genreId} = useParams();
  const channelName = params?.location?.state?.channelName;
  const supplierName = params?.location?.state?.supplierName;
  const categoryName = params?.location?.state?.categoryName;
  const languageName = params?.location?.state?.languageName;
  const genreName = params?.location?.state?.genreName;
    return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={(channelName||supplierName||categoryName||languageName||genreName||"")+" "+t("pages.Assets")} />

        <AssetsTable channelId={channelId} supplierId={supplierId} categoryId={categoryId} languageId={languageId} genreId={genreId}/>
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useState } from "react"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useJSONParser from "../../../hooks/useJSONParser"
// import HistoryModal from "../../../components/Common/HistoryModal"
import TableAction from "../../../components/TableAction"
import { Button, Input } from "reactstrap"
import { getAudits } from "../../../api/audits"
import moment from "moment"

const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

const parseAction = (action) => {
  return `User has ${action}${(action[action.length - 1] === "e") ? "d" : "ed"} an asset`
}
const getClassByAssetHistoryAction = (action) => {
  switch (action) {
    case "import": { return "badge bg-info" }
    case "create": { return "badge bg-success" }
    case "edit": { return "badge bg-warning" }
    case "delete": { return "badge bg-danger" }
  }
}

export default function AuditsTable() {
  const { t } = useTranslation()

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setMeta,
    meta,
    setPayload,
  } = usePagination(getAudits)

  // Asset Actions
  const AssetActions = [
    {
      label: "Create",
      value: "create"
    },
    {
      label: "Edit",
      value: "edit"
    },
    {
      label: "Delete",
      value: "delete"
    },
    {
      label: "Import",
      value: "import"
    }
  ]

  // filters
  const [filters, setFilters] = useState({
    action: null,
    username: null,
    email: null,
    assetName: null,
  })
  const searchDebounce = useCallback(
    debounce(fil => {
      setMeta({ page: 1, payload: fil })
    }, 500),
    []
  )

  useEffect(() => {
    setMeta({ page: 1, payload: filters })
  }, [filters.action])

  useEffect(() => {
    searchDebounce(filters)
  }, [filters.email])

  useEffect(() => {
    searchDebounce(filters)
  }, [filters.username])

  useEffect(() => {
    searchDebounce(filters)
  }, [filters.assetName])


  //// History 
  const [showHistory, setShowHistory] = useState(false)
  const [historyModalData, setHistoryModalData] = useState(null)

  function onShowHistoryModalClick(data) {
    console.log(data)
    setHistoryModalData(data)
    setShowHistory(true)
  }

  function onCloseHistoryModalClick() {
    setShowHistory(false)
  }

  return (
    <>
      <TableHeaderHandler
        setPage={setPage}
        setPerPage={setPerPage}
      >
        <div
          className="d-flex tableFilters"
          style={{ flexWrap: "wrap", marginBottom: "15px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ marginRight: '5px' }}>{t("forms.Username")}:</div>
            <Input
              name="username"
              style={{ marginRight: "10px" }}
              placeholder={t("forms.Username")}
              type="text"
              className="form-control"
              onChange={e => {
                setFilters({
                  ...filters,
                  username: e.target.value,
                })
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >

            <div style={{ marginRight: '5px' }}>{t("forms.Email")}:</div>
            <Input
              name="email"
              style={{ marginRight: "10px" }}
              placeholder={t("forms.Email")}
              type="text"
              className="form-control"
              onChange={e => {
                setFilters({
                  ...filters,
                  email: e.target.value,
                })
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ marginRight: '5px' }}>{t("forms.Asset")}:</div>
            <Input
              name="assetName"
              style={{ marginRight: "10px" }}
              placeholder={t("forms.Asset")}
              type="text"
              className="form-control"
              onChange={e => {
                setFilters({
                  ...filters,
                  assetName: e.target.value,
                })
              }}
            />
          </div>


          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >

            <div style={{ marginRight: '5px' }}>{t("tables.Action")}:</div>
            <select
              style={{ marginRight: "10px" }}
              onChange={e => {
                if (e.target.value != "all") {
                  setFilters({
                    ...filters,
                    action: e.target.value,
                  })
                } else {
                  setFilters({
                    ...filters,
                    action: null,
                  })
                }
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              {AssetActions.map(asset => {
                return (
                  <option value={asset.value} key={asset.value}>
                    {asset.label}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "20%" }}>{t("tables.Action")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Asset")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Username")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Email")}</Th>
                <Th style={{ width: "20%" }}>{t("tables.DoneAt")}</Th>
              </Tr>
            </Thead>

            {!loading && !error && (
              <Tbody>
                {response.data.audits.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '13px' }} className={getClassByAssetHistoryAction(row.action)}>{parseAction(row.action)}</div>
                          <i className="bx bx-history" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => onShowHistoryModalClick(row)}></i>
                        </div>
                      </Td>
                      <Td>{`${useJSONParser(row.asset.name)?.en} - ${useJSONParser(row.asset.name)?.ar}`}</Td>
                      <Td>{row.admin.username}</Td>
                      <Td>{row.admin.email}</Td>
                      <Td>{moment(row.createdAt).format('HH:MM:SS/YYYY-MM-DD')}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading && !error && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          {!loading && (
            <EmptyData
              empty={response.data && response.data.audits.length === 0}
              msg="No Audits Found."
            />
          )}

          {/* <HistoryModal
            show={showHistory}
            data={historyModalData}
            onCloseClick={onCloseHistoryModalClick}
          /> */}

          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

import React from "react";
import { useTranslation } from "react-i18next"

export default function EmptyData(props){

    const { t } = useTranslation()

    return(
        <>
        { props.empty &&
            <div style={{background: "#eff2f7",padding: "20px",textAlign: "center"}}>
            {t("emptyMsgs."+props.msg)}
            </div>   
        }
        </>
        
    )
}
import { myAxios } from "./axios"

export function getChannels(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            title: data ? data.txt : null,
        },
    }
    return myAxios.get("/channels", config)
}


export function getChannel(id) {
    return myAxios.get("/channels/" + id)
}

export function addChannel(data) {
    return myAxios.post("/channels/create", data)
}

export function editChannel(id, data) {
    return myAxios.put("/channels/" + id + "/update", data)
}

export function deleteChannel(id) {
    return myAxios.delete("/channels/" + id + "/delete")
}

export function publishChannel(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/channels/" + id + "/publish")
    } else {
        return myAxios.post("/channels/" + id + "/unpublish")
    }
}

export function importChannels(data) {
    return myAxios.post("/channels/import", data)
}
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import { deleteLanguage, getLanguages, publishLanguage } from "../../../api/languages"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "reactstrap"
import {PERMISSIONS} from "../../../api/permissions";

export default function LanguagesTable() {

  const { t } = useTranslation()


  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getLanguages)

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingLanguage, setDeletingLanguage] = useState(null)

  const { setData } = useResponse(deleteLanguage, () => {
    setShow(false)
    setDeletingLanguage(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingLanguage })
  }
  //// publish 
  const [publishShow, setPublishShow] = useState(false)

  function onClosePublish() {
    setPublishShow(false)
  }

  const { setData: setPublishLanguage } = useResponse(publishLanguage, () => {
    setPublishShow(false)
    setDeletingLanguage(null)
    setPayload({})
  })

  function onPublishClick() {
    setPublishLanguage({ type: "add", data: deletingLanguage })
  }
  const navigate = useHistory().push

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.LANGUAGES_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-language"
      />

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                {/*<Th style={{ width: "20%" }}>{t("forms.Image")}</Th>*/}

                <Th style={{ width: "20%" }}>ID</Th>
                <Th style={{ width: "50%" }}>{t("forms.Name")}</Th>
                {/* <Th style={{ width: "20%" }}>{t("forms.Code")}</Th> */}
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.languages.map(row => {
                  return (
                    <Tr key={row.id}>
                      {/*<Td>*/}
                      {/*  <img src={row.image_url} width="40px" height="40px" />*/}
                      {/*</Td>*/}
                      <Td>{row.id}</Td>

                      {/*<Td><Link to={"/view-language/" + row.id}>{row.name}</Link></Td>*/}
                      <Td><a href={""} onClick={(e)=> {
                        e.preventDefault()
                        navigate("/language/" + row.id + '/assets', {languageName: row.name})
                      }}>{row.name}</a></Td>

                      {/* <Td>{row.code}</Td> */}
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.LANGUAGES_EDIT}
                          deletePermission={PERMISSIONS.LANGUAGES_DELETE}
                          type="language"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingLanguage}
                        />
                        {/*<Button onClick={() => {*/}
                        {/*  setPublishShow(true)*/}
                        {/*  setDeletingLanguage(row.id)*/}
                        {/*}}>*/}
                        {/*    {row.is_published ? t("btns.Unpublish") : t("btns.Publish")}*/}
                        {/*</Button>*/}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.languages.length === 0} msg="No Languages Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          <DeleteModal
            show={publishShow}
            onDeleteClick={onPublishClick}
            onCloseClick={onClosePublish}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

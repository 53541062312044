import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editGenre, getGenre } from "../../../api/genres"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import GenreForm from "./GenreForm"

const EditGenre = () => {
    let navigate = useHistory()
    const { genreSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedGenre, setEditedGenre] = useState(null)

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getGenre,
        params.id,
        response => {
            setEditedGenre({
                ...response.data.data.genre,
                // Presenters: response.data.data.genre.Presenters.map(p => {
                //     return { value: p.id, label: p.name }
                // }),
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editGenre, () => {
        navigate.push("/genres")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: editedGenre ? editedGenre.name : "",
            // slug: editedGenre ? editedGenre.slug : "",
            // summary: editedGenre ? editedGenre.summary : "",
            // img: editedGenre ? editedGenre.image_url : "",
            // presenters: editedGenre ? editedGenre.Presenters : null,
            // cover_url: editedGenre ? editedGenre.cover_url : "",
            // order: editedGenre ? editedGenre.order : 0
        },
        validationSchema: genreSchema,
        onSubmit: values => {
            let data = {
                ...values,
                // image_url: values.img,
                // Presenters: values.presenters.map(p => {
                //     return p.value
                // }),
            }
            setData({ type: "edit", id: params.id, data })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Genres")}
                        breadcrumbItem={t("btns.Edit") + " " + t("pages.Genre")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedGenre && (
                                        <Form
                                            className="needs-validation"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <GenreForm validation={validation} />

                                            <FormButtonHandler btnTxt="Edit" loading={loading} />
                                        </Form>
                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditGenre
import { useState } from "react"
import { Accordion, AccordionBody, AccordionHeader, Button, Card, CardBody, Row } from "reactstrap"
import QueryGroup from "./QueryGroup"
import QueryGroupV2 from "./QueryGroupV2"

import './query-builder.css'

export default function SearchQueryBuilder({ style, filter, setFilter, onSubmit, onExportSubmit, fields }) {

    const [accordionOpen, setAccordionOpen] = useState(false);
    const accordionToggle = (id) => {
      if (accordionOpen === id) {
        setAccordionOpen(false);
      } else {
        setAccordionOpen(id);
      }
    };
    return (
        <Accordion toggle={accordionToggle} open={accordionOpen} style={{...style, backgroundColor: "white"}}>
            <AccordionHeader targetId="1">
                Search
            </AccordionHeader>
            <AccordionBody accordionId="1">
                <QueryGroupV2 handleSubmit={onSubmit} fields={fields} level={0} group={filter} onChangeFilter={(val) => {
                    setFilter(oldFilter => ({ ...oldFilter, ...val }))
                }} />

                <div className="d-flex" style={{ width: "100%", justifyContent: "flex-end", marginTop: 20 }}>
                    <Button color="primary" style={{ marginRight: 5 }} onClick={onSubmit}>Search</Button>
                    <Button color="warning" onClick={onExportSubmit}>Export</Button>
                </div>
            </AccordionBody>
        </Accordion>
    )
}

import React, { useEffect, useState } from "react";

import { Card, CardBody, Button } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useResponse } from "../hooks/useResponse";
import { importFile } from '../api/assets';
import ResultHandler from '../components/ResultHandler';
import {t} from "i18next";

export default function ImportCard({ cardStyle, cardClass, handleLoading, handleError, handleSuccess, handleCancel, api, fileName, listName }) {

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [successMsg, setSuccessMsg] = useState("");
    const { loading, error, success, setData:setPayload } = useResponse(api,(data)=>{
        const length = data.data[listName].length;
        setSuccessMsg(t("messages.Imported",{num:length}));

    });
    function handleUpload() {
        const data = new FormData();
        data.append('file', acceptedFiles[0]);
        setPayload({ type: "add", data });
    }

    useEffect(() => {
        if (handleLoading) {
            handleLoading(loading);
            handleError(error);
            handleSuccess(success);
        }
    }, [loading, error, success]);

    // tutorial
    const [tutorialOpen, setTutorialOpen] = useState(false);

    return (
        <Card style={cardStyle} className={cardClass}>
            <CardBody>
                <h4 className='mb-4' style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {t("tables.Import")}
                    </span>
                    <div className='d-flex'>
                        <a style={{ color: "white", textDecoration: "none", }} href={process.env.PUBLIC_URL + fileName} download="template.xlsx">

                        <Button color='info' style={{marginLeft: "10px" }}>
                                {t("btns.Download")}
                        </Button>
                        </a>

                        {acceptedFiles.length > 0 && !loading && <Button style={{ marginLeft: "10px" }} onClick={handleUpload} color='primary'>
                            Import
                        </Button>}
                        {acceptedFiles.length > 0 && loading && <Button style={{ marginLeft: "10px" }} color='dark'>
                            Uploading
                        </Button>}
                        {(success || error) && <Button onClick={handleCancel} style={{ marginLeft: "10px" }}>
                            Cancel
                        </Button>}
                    </div>
                </h4>
                <ResultHandler error={error} success={success} successMsg={successMsg}/>
                <section className="container" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0
                }}>
                    <div {...getRootProps({ className: 'dropzone' })} style={{width:"100%"}}>
                        <input {...getInputProps()} />
                        {acceptedFiles.length === 0 && <p className='mb-0' style={{ fontWeight: "bold", fontSize: "17px" ,textAlign:"center",padding:"10px",paddingTop:"50px",height:"100%"}}>
                            <i className="bx bx-cloud-upload" style={{ display: "block", margin: "auto", marginBottom: "15px" ,fontSize:"50px"}}></i>
                            Import File You Want
                        </p>}
                        {acceptedFiles.length > 0 && <p className='mb-0' style={{ fontWeight: "bold", fontSize: "17px" ,textAlign:"center",padding:"10px",paddingTop:"50px",height:"100%"}}>
                            <i className='bx bxs-check-circle' style={{ display: "block", margin: "auto", marginBottom: "15px",fontSize:"50px"}}></i>
                            {acceptedFiles[0].name}
                        </p>}
                    </div>
                </section>
            </CardBody>
        </Card>
    );
}

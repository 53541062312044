
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form, Table, Button, ButtonGroup, Accordion, AccordionHeader, AccordionBody, AccordionItem,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { editRole, getRole, addRole } from "../../../api/roles"
import ResultHandler from "../../../components/ResultHandler"
import FormButtonHandler from "../../../components/FormButtonHandler"
import { getAllPermissions } from "../../../api/admins"
import { getAllFields } from "../../../api/admins"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import { usePagination } from "../../../hooks/usePagination"
import SearchSelectBox from "../../../components/SearchSelectBox"
import Select from "react-select"
import { t } from "i18next"
import ModelFields from "./ModelFields"


export default function RoleForm({ validation, permissionsResponse, fieldsResponse }) {



  return (
    <>
      <FormGroup className="mb-3">
        <Label htmlFor="validationCustom01">
          {t("forms.Name")}
        </Label>
        <Input
          name="name"
          placeholder={t("forms.Name")}
          type="text"
          className="form-control"
          id="validationCustom01"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.name || ""}
          invalid={
            validation.touched.name &&
              validation.errors.name
              ? true
              : false
          }
        />
        {validation.touched.name &&
          validation.errors.name ? (
          <FormFeedback type="invalid">
            {validation.errors.name}
          </FormFeedback>
        ) : null}
      </FormGroup>
      <Row>
        <Col md="6">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">
              {t("forms.Permissions")}
            </Label>{" "}
            <br />
            {validation.errors.permissions && (
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#f46a6a",
                }}
              >
                {validation.errors.permissions}
              </div>
            )}

            {
              permissionsResponse.data.data.permissions.map((p) => {
                return <div key={p.id}>
                  <input type={"checkbox"} onChange={() => {
                    validation.setFieldValue('permissions', validation.values.permissions.includes(p.id) ? validation.values.permissions.filter((id) => id !== p.id) : [...validation.values.permissions, p.id])
                  }} checked={validation.values.permissions.includes(p.id)} />
                  <label style={{ margin: "0px 10px" }}>{p.name}</label>
                </div>
              })
            }
          </FormGroup>
        </Col>
        <Col md="6">

          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">
              {t("forms.Fields")}
            </Label>{" "}

            {validation.errors.fields && (
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#f46a6a",
                }}
              >
                {validation.errors.fields}
              </div>
            )}

            <div className="test">
              {fieldsResponse?.data?.data?.fields.map((model) => {
                return <ModelFields key={model.name} model={model} validation={validation} />
              })}
            </div>
          </FormGroup>
        </Col>

      </Row>
    </>
  )
}
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"
import SearchSelectBox from "./SearchSelectBox"
import SlugInput from "./SlugInput"
// import { getPresentersDropdown } from "../../../api/channels"
import ChooseImage from "./ChooseImage"
import { useTranslation } from "react-i18next"
import AddMoreInput from "./AddMoreInput"
import AddMoreInputV2 from "./AddMoreInputV2"
import moment from "moment/moment"
import useAllowedFields from "../hooks/useAllowedFields"


export default function CustomForm({ validation, model, fields }) {
    const { t } = useTranslation()

    function getDatabaseFieldName(f) {
        return ['nameEn', 'nameAr'].includes(f.key) ? 'name' : f.key
    }

    const getAllowedFields = useAllowedFields(model)

        function isFieldDisabled(f) {
        return !getAllowedFields().find(fi => fi.name == getDatabaseFieldName(f))?.RoleField?.privileges?.includes('write')
    }


    return (

        <Row>
            {fields.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => (
                <Col  key={f.key} md={6}>
                    <FormGroup>
                        {(!f.type || ['number', 'date', 'time', 'textarea'].includes(f.type)) && <>
                            <Label>{t("forms." + f.label)}</Label>
                            <Input
                                name={f.key}
                                disabled={isFieldDisabled(f)}
                                placeholder={t("forms." + f.label)}
                                type={f.type || "text"}
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={f.type === 'date' ? moment(validation.values[f.key]).format('YYYY-MM-DD') : (validation.values[f.key] || "")}
                                invalid={
                                    validation.touched[f.key] && validation.errors[f.key]
                                }
                            />
                        </>}
                        {f.type == 'checkbox' && <div className="d-flex" style={{ alignItems: "center" }}>
                            <input
                                name={f.key}
                                disabled={isFieldDisabled(f)}
                                placeholder={t("forms." + f.label)}
                                type={'checkbox'}
                                onChange={() => {
                                    if (validation.values[f.key]) {
                                        validation.setFieldValue(f.key, false)

                                    } else {
                                        validation.setFieldValue(f.key, true)
                                    }
                                }}
                                checked={(validation.values[f.key] || false)}

                            />
                            <Label style={{ margin: "0px 10px" }}>{t("forms." + f.label)}</Label>

                        </div>}
                        {f.type === 'select' && <>
                            <Label>{t("forms." + f.label)}</Label>
                            <SearchSelectBox
                                name={f.key}
                                api={f.api}
                                disabled={isFieldDisabled(f)}
                                type={f.apiResKey}
                                label={'name'}
                                setValue={(e) => {
                                    validation.setFieldValue(f.key, e.value)
                                }}
                                value={validation.values[f.key] || null}
                                error={
                                    validation.touched[f.key] && validation.errors[f.key]
                                }
                            />
                        </>}
                        {f.type == 'add-more' &&
                            <AddMoreInput
                                disabled={isFieldDisabled(f)}
                                label={t("forms." + f.label)}
                                values={validation.values[f.key] || ['New value']}
                                onChangeValues={(vals) => {
                                    validation.setFieldValue(f.key, vals)
                                }}
                            />
                        }
                        {f.type == 'add-more-v2' &&
                            <AddMoreInputV2
                                disabled={isFieldDisabled(f)}
                                label={t("forms." + f.label)}
                                values={validation.values[f.key] || []}
                                onChangeValue={(val, i, key) => {
                                    validation.setFieldValue(f.key, validation.values[f.key].map((pair, pairI) => {
                                        if (pairI == i) {
                                            return { ...pair, [key]: val }
                                        } else {
                                            return pair
                                        }
                                    }))
                                }}
                                schema={f.schema}
                                handleAddNewValue={() => {
                                    const data = {}
                                    f.schema.map(s => s.key).forEach((k) => {
                                        data[k] = ''
                                    })
                                    validation.setFieldValue(f.key, [...(validation.values[f.key] || []), data])
                                }}
                                handleRemovePair={(i) => {
                                    validation.setFieldValue(f.key, validation.values[f.key].filter((pair, pairI) => pairI != i))
                                }}
                            />
                        }
                        {validation.touched[f.key] &&
                        validation.errors[f.key] ? (
                            <FormFeedback type="invalid">
                                {validation.errors[f.key]}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>

                </Col>
            ))}
        </Row>

    )
}

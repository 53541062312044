
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"
import SearchSelectBox from "../../../components/SearchSelectBox"
import { useTranslation } from "react-i18next"
import AddMoreInput from "../../../components/AddMoreInput"
import AddMoreInputV2 from "../../../components/AddMoreInputV2"
import moment from "moment/moment"
import useAllowedFields from "../../../hooks/useAllowedFields"
import FIELDS, { AQUSITION_FIELDS, MEDIA_FIELDS, ROLE3_FIELDS } from "./CONSTANT"


const FieldInput = ({f, validation, isFieldDisabled}) => {
    const { t } = useTranslation()
   
    return(
        <Col key={f.key} md={f.width||6}>
            <FormGroup>
                {(!f.type || ['number', 'date', 'time', 'textarea'].includes(f.type)) && <>
                    <Label>{t("forms." + f.label)}</Label>
                    <Input
                        name={f.key}
                        disabled={isFieldDisabled(f) || f.disabled}
                        placeholder={t("forms." + f.label)}
                        type={f.type || "text"}
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={f.type === 'date' ? moment(validation.values[f.key]).format('YYYY-MM-DD') : (validation.values[f.key] || "")}
                        invalid={
                            validation.touched[f.key] && validation.errors[f.key]
                        }
                    />
                </>}
                {f.type == 'checkbox' && <div className="d-flex" style={{ alignItems: "center" }}>
                    <input
                        name={f.key}
                        disabled={isFieldDisabled(f) || f.disabled}
                        placeholder={t("forms." + f.label)}
                        type={'checkbox'}
                        onChange={() => {
                            if (validation.values[f.key]) {
                                validation.setFieldValue(f.key, false)

                            } else {
                                validation.setFieldValue(f.key, true)
                            }
                        }}
                        checked={(validation.values[f.key] || false)}

                    />
                    <Label style={{ margin: "0px 10px" }}>{t("forms." + f.label)}</Label>

                </div>}
                {f.type === 'select' && <>
                    <Label>{t("forms." + f.label)}</Label>
                    <SearchSelectBox
                        name={f.key}
                        api={f.api}
                        disabled={isFieldDisabled(f) || f.disabled}
                        type={f.apiResKey}
                        label={'name'}
                        setValue={(e) => {
                            validation.setFieldValue(f.key, e?.value || null)
                        }}
                        value={validation.values[f.key] || null}
                        error={
                            validation.touched[f.key] && validation.errors[f.key]
                        }
                    />
                </>}
                {f.type == "fixed-select" && (
                    <>
                        <Label>{f.label}</Label>
                        <select
                        name={f.key}
                        disabled={isFieldDisabled(f) || f.disabled}
                        onChange={validation.handleChange}
                        style={{ appearance: "auto" }}
                        className="form-control"
                        >
                        <option></option>
                        {f.options.map((op) => (
                            <option key={op} value={op.value || op}>
                            {op.label || op}
                            </option>
                        ))}
                        </select>
                    </>
                )}
                {f.type == 'add-more' &&
                    <AddMoreInput
                        disabled={isFieldDisabled(f) || f.disabled}
                        label={t("forms." + f.label)}
                        values={validation.values[f.key] || ['New value']}
                        onChangeValues={(vals) => {
                            validation.setFieldValue(f.key, vals)
                        }}
                    />
                }
                {f.type == 'add-more-v2' &&
                    <AddMoreInputV2
                        disabled={isFieldDisabled(f) || f.disabled}
                        label={t("forms." + f.label)}
                        values={validation.values[f.key] || []}
                        onChangeValue={(val, i, key) => {
                            validation.setFieldValue(f.key, validation.values[f.key].map((pair, pairI) => {
                                if (pairI === i) {
                                    return { ...pair, [key]: val }
                                } else {
                                    return pair
                                }
                            }))
                        }}
                        schema={f.schema}
                        handleAddNewValue={() => {
                            const data = {}
                            f.schema.map(s => s.key).forEach((k) => {
                                data[k] = ''
                            })
                            validation.setFieldValue(f.key, [...(validation.values[f.key] || []), data])
                            if(f.onSizeChanged){
                                f.onSizeChanged(validation,1)
                            }
                        }}
                        handleRemovePair={(i) => {
                            validation.setFieldValue(f.key, validation.values[f.key].filter((pair, pairI) => pairI !== i))
                            if(f.onSizeChanged){
                                f.onSizeChanged(validation,-1)
                            }
                        }}
                    />
                }
                {validation.touched[f.key] &&
                validation.errors[f.key] ? (
                    <FormFeedback type="invalid">
                        {validation.errors[f.key]}
                    </FormFeedback>
                ) : null}
            </FormGroup>
        </Col>
    )
}

export default function AssetForm({validation}){


    function getDatabaseFieldName(f) {
        return ['nameEn', 'nameAr'].includes(f.key) ? 'name' : f.key
    }

    const getAllowedFields = useAllowedFields("Asset")

    function isFieldDisabled(f) {
        return !getAllowedFields().find(fi => fi.name == getDatabaseFieldName(f))?.RoleField?.privileges?.includes('write')
    }

    return(
        <>
            <Row>
                <Col md={8}>
                    <Card>
                        <CardBody>
                            <Row>
                                {MEDIA_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => (
                                   <FieldInput isFieldDisabled={isFieldDisabled} key={f.key} f={f} validation={validation} />
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Row>
                                {AQUSITION_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => (
                                    <FieldInput isFieldDisabled={isFieldDisabled} key={f.key} f={f} validation={validation} />
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <CardBody>
                            <Row>
                                {ROLE3_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => (
                                <FieldInput isFieldDisabled={isFieldDisabled} key={f.key} f={f} validation={validation} />
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </>
    )
}

import { Button, Col, Input, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next"
import SearchSelectBox from "./SearchSelectBox";
import React from "react";
import moment from "moment";

export default function AddMoreInputV2({ label, values, onChangeValue, disabled, schema, handleAddNewValue, handleRemovePair }) {
    const { t } = useTranslation()

    return (
        <div>
            <Row className="mb-1">
                <Col md={10}>
                    <Label>{label} </Label>
                </Col>
                <Col md={2} style={{ textAlign: "right" }}>
                    <Button disabled={disabled} type="button" color="primary" onClick={() => handleAddNewValue()}>
                        <i className="bx bx-plus"></i>
                    </Button>
                </Col>
            </Row>

            <div>
                {values.map((v, pairI) => {
                    console.log("minaaaaaaa", v,schema);
                    return <Row key={pairI} className="d-flex mb-1">
                        {schema.map((s, si) => (
                            <Col md={10} className="mb-2">
                                {s?.type === 'select' ? <>
                                    <Label>{t("forms." + s.label)}</Label>
                                    <SearchSelectBox
                                        name={s.key}
                                        api={s.api}
                                        disabled={s.disabled}
                                        type={s.apiResKey}
                                        label={'name'}
                                        setValue={(e) => {
                                            onChangeValue(e?.value, pairI, s.key)
                                        }}
                                        value={v[s.key] || null}
                                    />
                                </> : <>
                                    <Label>{t("forms." + s.label)}</Label>
                                    <Input disabled={disabled} type={s?.type || 'text'} value={s.type === 'date' ? moment(v[s.key]).format('YYYY-MM-DD') : (v[s.key] || "")}
                                           onChange={(e) => onChangeValue(e.target.value, pairI, s.key)} />
                                </>}

                            </Col>

                        ))}
                        <Col md={2} style={{ textAlign: "right" }}>
                            <Button disabled={disabled} color="danger" type="button" onClick={() => handleRemovePair(pairI)}>
                                <i className="bx bx-minus"></i>
                            </Button>
                        </Col>
                    </Row>
                })}
            </div>

        </div>
    )

}

import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import { deleteGenre, getGenres, importGenres, publishGenre } from "../../../api/genres"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "reactstrap"
import { PERMISSIONS } from "../../../api/permissions";
import ImportModal from "../../../components/Common/ImportModal"

export default function GenresTable() {

  const { t } = useTranslation()


  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getGenres)

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingGenre, setDeletingGenre] = useState(null)

  const { setData } = useResponse(deleteGenre, () => {
    setShow(false)
    setDeletingGenre(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingGenre })
  }
  // import 
  const [showImport, setImport] = useState(false)

  function onCloseImportClick() {
    setImport(false)
  }
  //// publish 
  const [publishShow, setPublishShow] = useState(false)

  function onClosePublish() {
    setPublishShow(false)
  }

  const { setData: setPublishGenre } = useResponse(publishGenre, () => {
    setPublishShow(false)
    setDeletingGenre(null)
    setPayload({})
  })

  function onPublishClick() {
    setPublishGenre({ type: "add", data: deletingGenre })
  }
  const navigate = useHistory().push

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.GENRES_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-genre"
      >
        <Button onClick={() => {
          setImport(true)
        }} color="secondary" className="btn-add mx-2">
          {t("tables.Import")}
        </Button>
      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                {/*<Th style={{ width: "20%" }}>{t("forms.Image")}</Th>*/}
                <Th style={{ width: "20%" }}>ID</Th>
                <Th style={{ width: "50%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.genres.map(row => {
                  return (
                    <Tr key={row.id}>
                      {/*<Td>*/}
                      {/*  <img src={row.image_url} width="40px" height="40px" />*/}
                      {/*</Td>*/}
                      <Td>{row.id}</Td>
                      <Td><a href={""} onClick={(e)=> {
                        e.preventDefault()
                        navigate("/genre/" + row.id + '/assets', {genreName: row.name})
                      }}>{row.name}</a></Td>

                      {/*<Td><Link to={"/view-genre/" + row.id}>{row.name}</Link></Td>*/}
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.GENRES_EDIT}
                          deletePermission={PERMISSIONS.GENRES_DELETE}
                          type="genre"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingGenre}
                        />
                        {/*<Button onClick={() => {*/}
                        {/*  setPublishShow(true)*/}
                        {/*  setDeletingGenre(row.id)*/}
                        {/*}}>*/}
                        {/*    {row.is_published ? t("btns.Unpublish") : t("btns.Publish")}*/}
                        {/*</Button>*/}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.genres.length === 0} msg="No Genres Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          <DeleteModal
            show={publishShow}
            onDeleteClick={onPublishClick}
            onCloseClick={onClosePublish}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ImportModal show={showImport} onCloseClick={onCloseImportClick} api={importGenres} fileName='import-genres.xlsx' listName='genres'></ImportModal>

          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editCategory, getCategory } from "../../../api/categories"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import CategoryForm from "./CategoryForm"

const EditCategory = () => {
    let navigate = useHistory()
    const { categorySchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedCategory, setEditedCategory] = useState(null)

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getCategory,
        params.id,
        response => {
            setEditedCategory({
                ...response.data.data.category,
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editCategory, () => {
        navigate.push("/categories")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name_en: editedCategory ? editedCategory.name.en : "",
            name_ar: editedCategory ? editedCategory.name.ar : "",
        },
        validationSchema: categorySchema,
        onSubmit: values => {
            let data = {
                ...values,
                name : {
                    en : values.name_en,
                    ar : values.name_ar
                },
            }
            setData({ type: "edit", id: params.id, data })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Categories")}
                        breadcrumbItem={t("btns.Edit") + " " + t("pages.Category")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedCategory && (
                                        <Form
                                            className="needs-validation"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <CategoryForm validation={validation} />

                                            <FormButtonHandler btnTxt="Edit" loading={loading} />
                                        </Form>
                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditCategory
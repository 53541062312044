import React, { useEffect } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { addLanguage } from "../../../api/languages"
import ChooseImage from "../../../components/ChooseImage"
import FormButtonHandler from "../../../components/FormButtonHandler"
import SearchSelectBox from "../../../components/SearchSelectBox"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import SlugInput from "../../../components/SlugInput"
import LanguageForm from "./LanguageForm"

const AddLanguage = () => {
    const { t } = useTranslation()
    let navigate = useHistory()
    const { languageSchema } = useCustomValidation()
    const { setData, loading, error, success } = useResponse(addLanguage, () => {
        navigate.push("/languages")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: "",
            //code: "",
        },
        validationSchema: languageSchema,
        onSubmit: values => {
            let data = {
                ...values,
                // image_url: values.img,
                // Presenters: values.presenters.map(p => {
                //     return p.value
                // }),
            }
            setData({ data, type: "add" })
        },
    })



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Languages")}
                        breadcrumbItem={t("btns.Add") + " " + t("pages.Language")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <LanguageForm validation={validation} />

                                        <FormButtonHandler btnTxt="Add" loading={loading} />

                                        <ResultHandler
                                            error={error}
                                            success={success}
                                            successMsg={"Created Successfully!"}
                                        />
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddLanguage
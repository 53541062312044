import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import { deleteEpisode, getEpisodes } from "../../../api/episodes"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "reactstrap"
import SearchQueryBuilder from "../../../components/search-query-builder/SearchQueryBuilder"
import FIELDS from "./CONSTANT"
import useAllowedFields from "../../../hooks/useAllowedFields";
import {PERMISSIONS} from "../../../api/permissions";

export default function EpisodesTable({assetId}) {

  const { t } = useTranslation()

  const [filter, setFilter] = useState({
    type: "group",
    relation: "",
    children: [
      {
        type: "condition",
        field: "",
        value: "",
        operator: ""
      }
    ]
  })

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,

  } = usePagination(getEpisodes, { assetId: assetId })

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingEpisode, setDeletingEpisode] = useState(null)

  const { setData } = useResponse(deleteEpisode, () => {
    setShow(false)
    setDeletingEpisode(null)
    setPayload({assetId})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingEpisode })
  }

  // const getAllowedFields = useAllowedFields("Episode")

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.EPISODE_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl={(assetId?("/assets/"+assetId):"")+"/add-episode"}
      />
      <SearchQueryBuilder 
      style={{ marginBottom: "20px" }}
      fields={FIELDS.filter(f => {
        return !f.isNotSearchable
      })}
      filter={filter} setFilter={setFilter} onSubmit={() => setPayload({...filter,assetId})} />
      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                {/* <Th style={{ width: "40%" }}>{t("forms.Name En")}</Th>
                <Th style={{ width: "40%" }}>{t("forms.Name Ar")}</Th> */}
                <Th style={{ width: "40%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.Asset")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.Duration (Minutes)")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.qc Valid")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.Censorship Valid")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.Broadcast Code")}</Th>
                <Th style={{ width: "10%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.episodes.map(row => {
                  return (
                    <Tr key={row.id}>

                      {/* <Td>{typeof row.name === 'string' ? JSON.parse(row.name).en : row.name.en}</Td>
                      <Td>{typeof row.name === 'string' ? JSON.parse(row.name).ar : row.name.ar}</Td> */}
                      <Td>{row.name}</Td>
                      <Td>{row?.asset?.name?.ar}</Td>
                      <Td>{row?.duration}</Td>
                      <Td>
                        {row?.qcValid
                            ? <span style={{ color: 'green' }}>&#x2713;</span>
                            : <span style={{ color: 'red' }}>&#x2717;</span>
                        }
                      </Td>
                      <Td>
                        {row?.censorshipValid
                            ? <span style={{ color: 'green' }}>&#x2713;</span>
                            : <span style={{ color: 'red' }}>&#x2717;</span>
                        }
                      </Td>
                        <Td>{row?.broadcastCode}</Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.EPISODE_EDIT}
                          deletePermission={PERMISSIONS.EPISODE_DELETE}
                          type="episode"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingEpisode}
                        />

                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.episodes.length === 0} msg="No Episodes Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />


          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

import { Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";
import ImportCard from "../ImportCard";
import { MoonLoader } from "react-spinners";

export default function ImportModal(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);

    return (
        <>
            <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
                <ModalBody className="py-3 px-5">
                    <ImportCard
                        key={cancel}
                        cardClass={open ? "importTab" : "importTab hide"}
                        cardStyle={{}}
                        handleLoading={setLoading}
                        handleError={setError}
                        handleSuccess={setSuccess}
                        handleCancel={() => {
                            setCancel(!cancel);
                        }}
                        api={props.api}
                        fileName={props.fileName}
                        listName={props.listName}
                    />
                    <div onClick={() => setOpen(!open)} className="importIcon">
                        {!loading && !error && !success && (
                            <i className="bx bx-drive-file-move" style={{ color: "white" }} />
                        )}
                        {loading && (
                            <MoonLoader color="white" speedMultiplier={0.7} />
                        )}
                        {error && <i className="bx bx-error" style={{ color: "white" }} />}
                        {success && <i className="bx bx-check" style={{ color: "white" }} />}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"
import { PERMISSIONS } from "../../api/permissions";

const SidebarContent = props => {
  const { t } = useTranslation()

  const location = useLocation()
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    document.body.classList.remove("sidebar-enable")
  }, [location])
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              permissions.includes(PERMISSIONS.ADMIN_VIEW) && <li>
                <Link to="/admins">
                  <i className="bx bx-user"></i>
                  <span>{t("pages.Admins")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.ROLE_VIEW) &&
              <li>
                <Link to="/roles">
                  <i className="bx bx-user-circle"></i>
                  <span>{t("pages.Roles")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.ASSET_VIEW) &&
              <li>
                <Link to="/assets">
                  <i className="bx bx-file"></i>
                  <span>{t("pages.Assets")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.AUDITS_VIEW) &&
              <li>
                <Link to="/audits">
                  <i className="bx bx-history"></i>
                  <span>{t("pages.Audits")}</span>
                </Link>
              </li>
            }
             {
              permissions.includes(PERMISSIONS.EPISODE_VIEW) &&
              <li>
              <Link to="/episodes">
                <i className="bx bx-film"></i>
                <span>{t("pages.Episodes")}</span>
              </Link>
            </li>
            }

            {
              permissions.includes(PERMISSIONS.CATEGORY_VIEW) &&
              <li>
                <Link to="/categories">
                  <i className="bx bx-bookmarks"></i>
                  <span>{t("pages.Categories")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.CHANNEL_VIEW) &&
              <li>
                <Link to="/channels">
                  <i className="bx bx-tv"></i>
                  <span>{t("pages.Channels")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.GENRE_VIEW) &&
              <li>
                <Link to="/genres">
                  <i className="bx bx-adjust"></i>
                  <span>{t("pages.Genres")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.SUPPLIER_VIEW) &&
              <li>
                <Link to="/suppliers">
                  <i className="bx bx-money"></i>
                  <span>{t("pages.Suppliers")}</span>
                </Link>
              </li>
            }
            {
              permissions.includes(PERMISSIONS.LANGUAGE_VIEW) &&
              <li>
                <Link to="/languages">
                  <i className="bx bx-planet"></i>
                  <span>{t("pages.Languages")}</span>
                </Link>
              </li>}



          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent

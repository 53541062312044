import React, { useState } from "react"
import {Link, useHistory, useParams} from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import {deleteAsset, getAssets, searchAndExport, importFile, deleteAllAssets} from "../../../api/assets"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  AccordionItem,
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label
} from "reactstrap"
import SearchQueryBuilder from "../../../components/search-query-builder/SearchQueryBuilder"
import FIELDS from "./CONSTANT";
import useAllowedFields from "../../../hooks/useAllowedFields";
import ImportModal from "../../../components/Common/ImportModal";
import { PERMISSIONS } from "../../../api/permissions";
import './Assets.css'
import SearchSelectBox from "../../../components/SearchSelectBox";
import {getChannels} from "../../../api/channels";
import {getCategories} from "../../../api/categories";
import {getGenres} from "../../../api/genres";
import Select from "react-select";

const parseAction = ({ action, admin }) => {
  return `${admin.username} has ${action}${(action[action.length - 1] === "e") ? "d" : "ed"} an asset (${admin.role.name})`
}
const getClassByAssetHistoryAction = (action) => {
  switch (action) {
    case "import": { return "badge bg-info" }
    case "create": { return "badge bg-success" }
    case "edit": { return "badge bg-warning" }
  }
}
const formatDate = (a)=> {
  const t = new Date(a);
  const date = ('0' + t.getDate()).slice(-2);
  const month = ('0' + (t.getMonth() + 1)).slice(-2);
  const year = t.getFullYear();
  return `${date}/${month}/${year}`;
}
export default function AssetsTable({channelId,supplierId,categoryId,languageId,genreId}) {
  const { t } = useTranslation()
  const navigate = useHistory()

  // const [filter, setFilter] = useState({
  //   type: "group",
  //   relation: "AND",
  //   children: [
  //     {
  //       type: "condition",
  //       field: "",
  //       value: "",
  //       operator: ""
  //     }
  //   ]
  // })

  const [filter, setFilter] = useState({
    type: "group",
    relation: "AND",
    children: [
      {
        type: "condition",
        field: "",
        value: "",
        operator: "like"
      }
    ]
  })

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,

  } = usePagination(getAssets,{channelId:channelId,supplierId,categoryId,languageId,genreId})

  // Handle export
  const {
    setData: searchAndExportSetPayload,
  } = useResponse(searchAndExport, (data) => {
    if (data.success === true) {
      window.open(data.data.pdfFileUrl, `data_${new Date().getTime()}`);
      window.location.href = data.data.excelFileUrl
    } else {
      console.log("export failed!", data)
    }
  })

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }
  function onCloseAllClick() {
    setDeleteAllShow(false)
  }

  //// import
  const [showImport, setImport] = useState(false)

  function onCloseImportClick() {
    setImport(false)
  }

  const [deletingAsset, setDeletingAsset] = useState(null)

  const { setData } = useResponse(deleteAsset, () => {
    setShow(false)
    setDeletingAsset(null)
    setPayload({channelId:channelId,supplierId,categoryId,languageId,genreId})
  })
  let [deleteAllAssetsCat,setDeleteAllAssetsCat] = useState(null);

  const { setData:setDeleteAllData } = useResponse(deleteAllAssets, () => {
    setDeleteAllShow(false)
    setDeleteAllAssetsCat(null)
    // refresh table

    setPayload({channelId:channelId,supplierId,categoryId,languageId,genreId})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingAsset })
  }

  function onDeleteAllClick() {
    setDeleteAllData({ type: "add", data: deleteAllAssetsCat })
  }

  function getDatabaseFieldName(f) {
    return ['nameEn', 'nameAr'].includes(f.key) ? 'name' : f.key
  }
  const getAllowedFields = useAllowedFields("Asset")
  const allowedFields = [{ key: 'all', label: "All Fields" },{ key: 'name', label: "Name", isJson: true }, ...FIELDS.filter(f => {
    return !f.isNotSearchable && getAllowedFields().some(obj => obj.name === getDatabaseFieldName(f))
  })]
  const columnsVisibility = [ ...FIELDS.filter(f => {
    return getAllowedFields().some(obj => obj.name === getDatabaseFieldName(f))
  })]
const [deleteAllShow, setDeleteAllShow] = useState(false)
  // Table Filter Accordion

  const [colsVisibilityOpen, setColsVisibilityOpen] = useState(false)

  const defaultNameField = [{ key: 'nameAr', label: "Arabic Name", isNotSearchable: true, },{ key: 'channelId', label: "Channel"},{ key: 'categoryId', label: "Category"},{ key: 'genreId', label: "Genre",},]
  if (!localStorage.getItem("assetsTableColumns")) {
    localStorage.setItem("assetsTableColumns", JSON.stringify(defaultNameField))
  }
  const [tableColumns, setSelectedTableColumns] = useState(JSON.parse(localStorage.getItem("assetsTableColumns")) ?? []);
  const [expirationDateFilter, setExpirationDateFilter] = useState(null);
  // get admin permissions
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  return (
    <>

      <TableHeaderHandler
        permission={PERMISSIONS.ASSET_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-asset"
      >


        <div style={{ display: "flex", height: "100%" }}>

          <Dropdown color="primary" style={{ height: "100%" }} isOpen={colsVisibilityOpen} direction="down" toggle={() => setColsVisibilityOpen(!colsVisibilityOpen)}>
            <DropdownToggle style={{ height: "100%" }} color="dark" onClick={() => setColsVisibilityOpen(!colsVisibilityOpen)}>
              Columns visibility
              <i className="bx bx-chevron-down" style={{ marginLeft: "10px",marginRight: "10px" }}></i>
            </DropdownToggle>
            <DropdownMenu className="cols-dropdown-menu" >
              {columnsVisibility.map(f => {
                const field = tableColumns.find((o) => o.key === f.key);
                const isCheckedField = field !== undefined;

                return <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation()
                    setColsVisibilityOpen(true)
                    if (field) {
                      const updatedTableColumns = [...tableColumns.filter(o => o.key != f.key)]
                      setSelectedTableColumns(updatedTableColumns)
                      localStorage.setItem("assetsTableColumns", JSON.stringify(updatedTableColumns))
                    } else {
                      const updatedTableColumns = [...tableColumns, f]
                      setSelectedTableColumns(updatedTableColumns)
                      localStorage.setItem("assetsTableColumns", JSON.stringify(updatedTableColumns))
                    }
                  }}
                  key={f.key}
                  style={{ display: "flex", justifyContent: "space-between", flexDirection: "row-reverse", alignItems: "center", paddingLeft: "20px", paddingRight: "20px" }}>
                  <input
                    id={f.key}
                    name={f.label}
                    placeholder={f.label}
                    type={'checkbox'}
                    onClick={(e) => {
                      setColsVisibilityOpen(true)
                      e.stopPropagation()

                    }}
                    onChange={(e) => {
                      setColsVisibilityOpen(true)
                      // if field exists in the array, remove it
                      if (field) {
                        const updatedTableColumns = [...tableColumns.filter(o => o.key != f.key)]
                        setSelectedTableColumns(updatedTableColumns)
                        localStorage.setItem("assetsTableColumns", JSON.stringify(updatedTableColumns))
                      } else {
                        const updatedTableColumns = [...tableColumns, f]
                        setSelectedTableColumns(updatedTableColumns)
                        localStorage.setItem("assetsTableColumns", JSON.stringify(updatedTableColumns))
                      }
                    }}
                    checked={isCheckedField}
                    style={{
                      marginRight: '5px'
                    }}
                  />
                  <label htmlFor={f.key} style={{ fontSize: '13px', marginBottom: 0 }}>{f.label}</label>
                </DropdownItem>
              })}
            </DropdownMenu>
          </Dropdown>
          {
            permissions.includes(PERMISSIONS.ASSET_IMPORT) &&
            <Button onClick={() => {
              setImport(true)
            }} color="secondary" className=" btn-add mx-2">
              {t("tables.Import")}
            </Button>
          }
        </div>
      </TableHeaderHandler>
      <div className={"row"}>
        <div className={"col-6"}>
          <div className={"row"}>
            <Label>Channel</Label>

            <div className={"col-6"}>
              <SearchSelectBox
                  name="channel"
                  api={getChannels}
                  // disabled={isFieldDisabled(f)}
                  type={"channels"}
                  label={'name'}
                  setValue={(e) => {
                    setDeleteAllAssetsCat(e.value);
                  }}
                  value={deleteAllAssetsCat}
                  // error={
                  //     validation.touched[f.key] && validation.errors[f.key]
                  // }
              />

            </div>
            <div className={"col-6"}>
              <Button
                  color="danger"
                  onClick={() => {
                    setDeleteAllShow(true)
                    // props.setDeleteIntitiy(props.id)
                  }}
                  style={{ marginRight: '5px',marginBottom: '5px' }}
              >
                Delete All
              </Button>
            </div>
          </div>

        </div>
        <div className={"col-6"}>
          <div className={"row"}>
            <Label>Rights Date</Label>

            <div className={"col-12"}>
              <Select
                  styles={{
// Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  options={
                [
                  { value: null, label: "All" },
                  { value: "expired", label: "🔴 Expired" },
                  { value: "month", label: "🟡 One Month Left" },]
              } value={expirationDateFilter==="expired"?{ value: "expired", label: "🔴 Expired" }:expirationDateFilter==="month"?{ value: "month", label: "🟡 One Month Left" }:null}
                      onChange={
                 (e) => {
                   setExpirationDateFilter(() => e.value)
                   setPayload({...filter, expirationDate: e.value,channelId:channelId,supplierId,categoryId,languageId,genreId})

                 }
              }/>


            </div>
            {/*<div className={"col-6"}>*/}
            {/*  <Button*/}
            {/*      color="primary"*/}
            {/*      onClick={() => {*/}
            {/*        console.log(expirationDateFilter)*/}
            {/*        setFilter(oldFilter => ({...oldFilter, expirationDate: expirationDateFilter}))*/}
            {/*        setPayload({...filter, expirationDate: expirationDateFilter})*/}
            {/*        // props.setDeleteIntitiy(props.id)*/}
            {/*      }}*/}
            {/*      style={{ marginRight: '5px',marginBottom: '5px' }}*/}
            {/*  >*/}
            {/*    Filter*/}
            {/*  </Button>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>

      <br/>

      <Row>
        <Col md={12}>
          <SearchQueryBuilder
            style={{ marginBottom: "20px" }}
            fields={allowedFields}
            filter={filter}
            setFilter={setFilter}
            onSubmit={() => setPayload({...filter, channelId: channelId, supplierId, categoryId, languageId, genreId})}
            onExportSubmit={() => searchAndExportSetPayload({ data: {...filter,channelId:channelId,supplierId,categoryId,languageId,genreId}, type: "add" })}
          />
        </Col>
      </Row>

      <div>

        {/*<Accordion toggle={accordionToggle} open={accordionOpen}>
          <AccordionItem className="mb-4">
            <AccordionHeader targetId="1">
              Outline the table's content
            </AccordionHeader >
            <AccordionBody accordionId="1">
              <div>
                {allowedFields.map(f => {
                  const field = tableColumns.find((o) => o.key === f.key);
                  const isCheckedField = field != undefined;

                  return <div className="mb-2" key={f.key} style={{ border: '1px black',display:"inline-block", justifyContent: "center", alignItems: "center", marginRight: '15px', marginBottom: '1rem' }}>
                    <input
                      id={f.key}
                      name={f.label}
                      placeholder={f.label}
                      type={'checkbox'}
                      onChange={() => {
                        // if field exists in the array, remove it
                        if (field) {
                          const updatedTableColumns = [...tableColumns.filter(o => o.key != f.key)]
                          setSelectedTableColumns(updatedTableColumns)
                          localStorage.setItem("assetTableColumns", JSON.stringify(updatedTableColumns))
                        } else {
                          const updatedTableColumns = [...tableColumns, f]
                          setSelectedTableColumns(updatedTableColumns)
                          localStorage.setItem("assetTableColumns", JSON.stringify(updatedTableColumns))
                        }
                      }}
                      checked={isCheckedField}
                      style={{
                        marginRight: '5px'
                      }}
                    />
                    <label htmlFor={f.key} style={{ fontWeight: "500", fontSize: '15px', marginRight: '0.5rem', marginBottom: 0 }}>{f.label}</label>
                  </div>
                })}
              </div>
            </AccordionBody>
          </AccordionItem>
              </Accordion>*/}
      </div>
      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                {
                  tableColumns.map(column => {
                    switch (column.key) {
                      case "name": return [<Th key={column.key} style={{ width: "22%" }}>{t(`forms.${column.label} Ar`)}</Th>, <Th style={{ width: "22%" }}>{t(`forms.${column.label} En`)}</Th>]
                      default: return <Th key={column.key} style={{ width: "22%" }}>{t(`forms.${column.label}`)}</Th>
                    }
                  })
                }
                {/*<Th style={{ width: "25%" }}>{t("tables.AssetActionHistory")}</Th>*/}
                <Th style={{ width: "15%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.assets.map(row => {
                  const is_expired = (row.rightsExpDate)&&(new Date(row.rightsExpDate)<new Date());
                  // Check if there's one month left until expiration
                  const oneMonthLeft = !is_expired && row.rightsExpDate &&
                      new Date(row.rightsExpDate) > new Date() &&
                      new Date(row.rightsExpDate) <= new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

                  return (
                    <Tr key={row.id} style={{background:((is_expired)?"rgba(255,0,0,0.2)":(oneMonthLeft?"rgb(254 255 0 / 11%)":""))}}>
                      {
                        tableColumns.map(column => {
                          const compoundColKey = column.key.includes("Id") ? column.key.replace("Id", '') : column.key

                          switch (column.key) {
                            case "nameAr": return [
                              <Td style={{background:"inherit"}} key={column.key}><Link to={"/view-asset/"+row.id}>{typeof row.name === 'string' ? JSON.parse(row.name)?.ar : row.name?.ar}</Link></Td>,
                              // <Td key={column.key}>{typeof row[column.key] === 'string' ? JSON.parse(row[column.key])?.en : row[column.key]?.en}</Td>,
                            ]

                            case "nameEn": return [
                              // <Td key={column.key}>{typeof row[column.key] === 'string' ? JSON.parse(row[column.key])?.ar : row[column.key]?.ar}</Td>,
                              <Td style={{background:"inherit"}} key={column.key}>{typeof row.name === 'string' ? JSON.parse(row.name)?.en : row.name?.en}</Td>,
                            ]

                            default: return <Td style={{background:"inherit"}} key={column.key}>
                              {
                                column.type === "date"? (row[compoundColKey]?formatDate(row[compoundColKey]):"") :
                                typeof row[compoundColKey] !== 'object' ? row[compoundColKey] :
                                  Array.isArray(row[compoundColKey]) ? row[compoundColKey].join():
                                (typeof row[compoundColKey]?.name === 'string' ? row[compoundColKey]?.name : row[compoundColKey]?.name?.en)}
                            </Td>
                          }
                        })
                      }
                      { /*<Td>
                        <span style={{ width: "10%", flexWrap: "wrap" }} className="d-flex">
                          {row.adminsActions.map((action) => {
                            return <span style={{ fontSize: "12px", marginRight: "5px", marginBottom: "5px" }} className={getClassByAssetHistoryAction(action.action)} key={action.id}>{parseAction(action)}</span>
                          })}
                        </span>
                        </Td>*/}
                      <Td style={{backgroundColor:"inherit"}}>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.ASSET_EDIT}
                          deletePermission={PERMISSIONS.ASSET_DELETE}
                          type="asset"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingAsset}
                        >
                          <Button
                              onClick={() => {
                                navigate.push("/assets/" + row.id + "/episodes",{assetName:row?.name?.ar})
                              }}
                              style={{ marginRight: '5px',marginBottom: '5px',backgroundColor: '#ffa500' ,borderColor: '#ffa500' }}
                          >
                            <i className="bx bx-film font-size-16 align-middle" />
                          </Button>
                        </TableAction>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.assets.length === 0} msg="No Assets Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />
          <DeleteModal
              show={deleteAllShow}
              onDeleteClick={onDeleteAllClick}
              onCloseClick={onCloseAllClick}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}

          <ImportModal show={showImport} onCloseClick={onCloseImportClick} api={importFile} listName='assets' fileName='Template.xlsx'></ImportModal>

          <ResultHandler error={error} loading={loading} />

        </div>
      </div>
    </>
  )
}

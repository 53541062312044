import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import RolesTable from "./RolesTable"
import { useTranslation } from "react-i18next"

export default function RolesList() {
  const { t } = useTranslation()

  return (
    <div className="page-content">
      <div className="container-fluid">
      <Breadcrumbs title="" breadcrumbItem={t("pages.Roles")} />

        <RolesTable />
      </div>
    </div>
  )
}

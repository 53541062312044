import { myAxios } from "./axios"

export function getEpisodes(data, { page, perPage }) {
    console.log(3333333,data);
    let config = {
        params: {
            page,
            perPage,
            filter: data,
            assetId: data?.assetId,

        },
    }
    return myAxios.get("/episodes", config)
}


export function getEpisode(id) {
    return myAxios.get("/episodes/" + id)
}

export function addEpisode(data) {
    return myAxios.post("/episodes/create", data)
}

export function editEpisode(id, data) {
    return myAxios.put("/episodes/" + id + "/update", data)
}

export function deleteEpisode(id) {
    return myAxios.delete("/episodes/" + id + "/delete")
}


import { getCategories } from "../../../api/categories"
import { getChannels } from "../../../api/channels"
import { getGenres } from "../../../api/genres"
import { getLanguages } from "../../../api/languages"
import { getSuppliers } from "../../../api/suppliers"

export const MEDIA_FIELDS = [
    { key: 'nameEn', label: "English Name", isNotSearchable: true, },
    { key: 'nameAr', label: "Arabic Name", isNotSearchable: true, },

    { key: 'program_type', label: "Program Type", type: "fixed-select", options: ['live', 'recorded'] },


    { key: 'channelId', label: "Channel", type: 'select', apiResKey: "channels", api: getChannels, },
    { key: 'categoryId', label: "Category", type: 'select', apiResKey: "categories", api: getCategories, },
    { key: 'genreId', label: "Genre", type: 'select', apiResKey: "genres", api: getGenres, },
    { key: 'languageId', label: "Language", type: 'select', apiResKey: "languages", api: getLanguages, },

    { key: 'director', label: "Director", },
    { key: 'writer', label: "Writer", },
    { key: "cast", label: "Cast", type: "add-more", },

    { key: "posters", label: "Posters Link", },
    { key: "promo", label: "Promo Link", },

    { key: "productionYear", label: "Production Year", type: "number", },
    { key: "productionCompany", label: "Production Company", },

    { key: "numOfEpisodes", label: "Number Of Episodes", type: "number", },
    { key: "duration", label: "Duration (Minutes)", type: "number", },

    { key: "deliveryDate", label: "Delivery Date", type: "date", },
    { key: "broadcastDate", label: "Broadcast Date", type: "date", },

    { key: "keywords", label: "Keywords", type: "add-more", },
    { key: 'presenter', label: "Presenter" },
    { key: "deliveryNote", label: "Delivery Note" },
    { key: "synopsis", label: "Synopsis", type: "textarea", width: 12 },
    {key: "broadcastCode", label: "Broadcast Code",},
    {key: "location", label: "Location",},

]

export const AQUSITION_FIELDS = [
    { key: 'supplierId', label: "Supplier", type: 'select', apiResKey: "suppliers", api: getSuppliers, },
    { key: "rightsExpDate", label: "Rights Expiration Date", type: "date", },
    { key: "numOfContractedBroadcasts", label: "Number Of Broadcast Contracts", type: "number", },
    { key: "numOfActualBroadcasts", label: "Number Of Actual Broadcasts", type: "number",  disabled: true, },
    { key: "rightsStartDate", label: "Rights Start Date", type: "date", },
    { key: "rightsPeriod", label: "Rights Period", type: "number", },
    { key: "rightsBlockingPeriodFrom", label: "Rights Blocking Period From", type: "date", },
    { key: "rightsBlockingPeriodTo", label: "Rights Blocking Period To", type: "date", },
    { key: 'copyrights', label: "Copyrights", type: "fixed-select", options: ['synced', 'exclusive', 'available'] },
    { key: 'broadcasts', label: "BroadCasts", type: "add-more-v2", onSizeChanged: (formik,value) => formik.setFieldValue('numOfActualBroadcasts', +formik.values.numOfActualBroadcasts + value),
        schema: [
            { key: 'channelId', label: 'Channel', type: 'select', apiResKey: "channels", api: getChannels },
            { key: 'date', label: 'Date', type: 'date' },
        ],

    },

]

export const ROLE3_FIELDS = [

    { key: "sellingPercentage", label: "Selling Percentage", type: "number", width: 12 },
    { key: "cost", label: "Cost", type: "number", width: 12 },
    { key: "partner", label: "Partner", type: "number", width: 12 },
    {
        key: "buyers", label: "Buyers", type: "add-more-v2",
        schema: [
            { key: 'soldTo', label: 'Sold To', type: 'text' },
            { key: 'sellingPrice', label: 'Selling Price', type: 'number' },
        ],
        width: 12
    },
]

const FIELDS = [
    ...MEDIA_FIELDS,
    ...AQUSITION_FIELDS,
    ...ROLE3_FIELDS,

]

export default FIELDS



import React from "react"
import { Button, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function PaginationHandler(props) {
  const { t } = useTranslation()
  if (props.totalPages <= 1) {
    return <></>
  }
  return (
    <Row
      style={{ margin: "30px 0px 30px 0px", width: "100%" }}
      className="justify-content-md-end justify-content-center align-items-center"
    >
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            disabled={props.page > 1 ? false : true}
            color="primary"
            onClick={() => {
              props.setPage(1)
            }}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            disabled={props.page > 1 ? false : true}
            onClick={() => {
              props.setPage(props.page - 1)
            }}
          >
            {"<"}
          </Button>
        </div>
      </Col>
      <Col className="col-md-auto d-none d-md-block">
        {t("tables.Page")}{" "}
        <strong>
          {props.page} from {props.totalPages}
        </strong>
      </Col>
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            disabled={props.totalPages > props.page ? false : true}
            color="primary"
            onClick={() => {
              props.setPage(props.page + 1)
            }}
          >
            {">"}
          </Button>
          <Button
            disabled={props.totalPages > props.page ? false : true}
            color="primary"
            onClick={() => {
              props.setPage(props.totalPages)
            }}
          >
            {">>"}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {Link, useHistory, useParams} from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editAsset, getAsset } from "../../../api/assets"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"

import useAllowedFields from "../../../hooks/useAllowedFields"
import CustomForm from "../../../components/CustomForm"
import FIELDS, {AQUSITION_FIELDS, MEDIA_FIELDS, ROLE3_FIELDS} from "./CONSTANT"
import AssetForm from "./AssetForm"
import {Td} from "react-super-responsive-table";
function getDatabaseFieldName(f) {
    return ['nameEn', 'nameAr'].includes(f.key) ? 'name' : f.key
}
const EditAsset = () => {
    let navigate = useHistory()
    const { assetSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedAsset, setEditedAsset] = useState(null)
    const getAllowedFields = useAllowedFields('Asset')

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getAsset,
        params.id,
        response => {
            setEditedAsset({
                ...response.data.data.asset,
                // Presenters: response.data.data.asset.Presenters.map(p => {
                //     return { value: p.id, label: p.name }
                // }),
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editAsset, () => {
        navigate.push("/assets")
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Assets")}
                        breadcrumbItem={ t("pages.Asset")+ " Details"}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    {editedAsset && (
                                        <Row>
                                            <Col md={8}>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            {MEDIA_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => {
                                                                const value = editedAsset[f.key.replace("Id", '')]
                                                                let last_value = ''
                                                                if (f.key === 'nameEn'){
                                                                    last_value = typeof editedAsset.name === 'string' ? JSON.parse(editedAsset.name)?.en : editedAsset.name?.en
                                                                }else if (f.key === 'nameAr'){
                                                                    last_value = typeof editedAsset.name === 'string' ? JSON.parse(editedAsset.name)?.ar : editedAsset.name?.ar
                                                                }else if (Array.isArray(value)) {
                                                                    last_value = value.join()
                                                                }else{
                                                                    last_value = typeof value !== 'object' ? value : (typeof value?.name === 'string' ? value?.name : value?.name?.en)
                                                                }
                                                                return <><Col sm={6}>
                                                                    <Label>{t("forms." + f.label)}</Label>
                                                                </Col>
                                                                <Col sm={6}>

                                                                    {last_value
                                                                        ? <span>{last_value}</span>
                                                                        : <span>---</span>}

                                                                </Col></>
                                                            })}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            {AQUSITION_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => {
                                                                const value = editedAsset[f.key.replace("Id", '')]
                                                                return <>
                                                                <Col sm={6}>
                                                                    <Label>{t("forms." + f.label)}</Label>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    {value
                                                                        ? <span>{typeof value !== 'object' ? value : (typeof value?.name === 'string' ? value?.name : value?.name?.en)}</span>
                                                                        : <span>---</span>}
                                                                </Col>

                                                                </>
                                                            })}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md={4}>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            {ROLE3_FIELDS.filter(f => getAllowedFields().map(fi => fi.name).includes(getDatabaseFieldName(f))).map(f => {
                                                                const value = editedAsset[f.key.replace("Id", '')]
                                                                return <>
                                                                <Col sm={6}>
                                                                    <Label>{t("forms." + f.label)}</Label>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    {value
                                                                        ? <span>{typeof value !== 'object' ? value : (typeof value?.name === 'string' ? value?.name : value?.name?.en)}</span>
                                                                        : <span>---</span>}
                                                                </Col>
                                                                </>
                                                            })}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}

                                    <ResultHandler
                                        loading={instenceLoading}
                                        error={error}
                                        success={success}
                                        successMsg={"Updated Successfully!"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditAsset

import i18next from "i18next"
import * as Yup from "yup"
import React from "react"
import { useTranslation } from "react-i18next"

const slugReg = new RegExp("^[A-Za-z\u0621-\u064A-]*$")
const passwordReg = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
)

const contentCard = Yup.object().shape({
  id: Yup.number(),
  order: Yup.number(),
  type: Yup.string(),
  cardRef: Yup.string().nullable(),
  content: Yup.string().nullable(),
  status: Yup.string(),
  meta: Yup.array().nullable(),
  isOpen: Yup.boolean(),
})



export default function useCustomValidation() {

  const { t } = useTranslation()

  const strongPasswordMsg = t("validation.strongPass")

  const loginSchema = Yup.object({
    email: Yup.string().required(t("validation.email")).nullable(),
    password: Yup.string().required(t("validation.password")).nullable(),
  })

  const addAdminSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    language: Yup.string(),

    password: Yup.string()
      .required(t("validation.password"))
      .matches(passwordReg, strongPasswordMsg),
    confirmPassword: Yup.string()
      .required(t("validation.confirmPass"))
      .oneOf([Yup.ref("password"), null], t("validation.confirmPass")),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    role: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.role")),
  })

  const editAdminSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    language: Yup.string(),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    role: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.role")),
  })

  const editProfileSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    password: Yup.string().nullable(),
    img: Yup.string().nullable(),
  })

  const categorySchema = Yup.object({
    // img: Yup.string().nullable().required(t("validation.img")),
    // name: Yup.string().nullable().required(t("validation.name")),
  })

  const homeSettingsSchema = Yup.object({
    categories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
  })

  const footerSettingsSchema = Yup.object({
    text: Yup.string(),
    copyright: Yup.string(),
    facebookUrl: Yup.string(),
    instagramUrl: Yup.string(),
    youtubeUrl: Yup.string(),
    twitterUrl: Yup.string(),
    logo: Yup.string(),
  })

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;

    return img
  };

  const coverDimentionsValidation = (url) => {
    let valid;
    if (url) {
      let img = getMeta(url, (err, img) => { });
      console.log('hhhh', img.naturalWidth, img.naturalHeight)

      const ratio = 1280 / 430

      if (img.naturalWidth / img.naturalHeight != ratio) {
        valid = false
      } else {
        valid = true
      }
    } else {
      valid = true
    }

    return valid ? {
      isValid: true,
    } : {
      isValid: false,
      errorMessage: t('validation.dimentions'),
    }
  }

  const programSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    summary: Yup.string().required(t("validation.summary")),
    slug: Yup.string()
      .nullable()
      .matches(slugReg, t("validation.slugMtch")),
    presenters: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable()
      .required(t("validation.presenters"))
      .min(1, t("validation.presenters")),
    img: Yup.string().required(t("validation.img")),
    cover_url: Yup.string().test('validator-cover_url', function (value) {
      const validation = coverDimentionsValidation(value);
      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage,
        });
      }
      else {
        return true;
      }
    })
  })

  const episodeSchema = Yup.object({
    // name: Yup.string().required(t("validation.name")),
    // date: Yup.date().required(t("validation.date")),
    // embedCode: Yup.string().required(t("validation.embededCode")),
    // image_url: Yup.string().required(t("validation.img")),
    // external_video: Yup.boolean().nullable(),
  })

  const presenterSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    summary: Yup.string().required(t("validation.summary")),
    img: Yup.string().required(t("validation.img")),
  })

  const roleSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    permissions: Yup.array()
      .of(Yup.number())
      .required(t("validation.permissions"))
      .min(1, t("validation.permissions")),
    types: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .nullable(),
  })

  const postSchema = Yup.object({
    title: Yup.string().required(t("validation.title")),
    writer: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.writer")),
    slug: Yup.string()
      .matches(slugReg, t("validation.slugMtch"))
      .nullable()
      .required(t("validation.slugReq")),
    mainImage_url: Yup.string().nullable(),
    mainImage_comment: Yup.string()
      .nullable()
      .when("mainImage_url", {
        is: mainImage_url => (mainImage_url ? true : false) === true,
        then: Yup.string()
          .nullable()
          .required(t("validation.mainImgComment")),
      }),
    language: Yup.string().nullable(),
    type: Yup.string().nullable(),
    special: Yup.boolean().nullable(),
    is_pinned: Yup.boolean().nullable(),

    summary: Yup.string().nullable(),
    priority: Yup.number().nullable(),
    stage_id: Yup.number().nullable(),
    categories: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable(),
    keywords: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .nullable(),

    kickers: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable(),
    related_posts: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    tags: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    publishedAt: Yup.date().nullable(),
    contents: Yup.object().shape({
      columns: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          title: Yup.string(),
          cards: Yup.array().of(contentCard),
        })
      ),
    }),
  })

  const stageSchema = Yup.object({
    name: Yup.string().required(t("validation.NameInArabic")),
    name_en: Yup.string().required(t("validation.NameInEnglish")),
  })

  const taskSchema = Yup.object({
    text: Yup.string().required(t("validation.text")),
  })

  const folderSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
  })

  const creatorSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    description: Yup.string().required(t("validation.bio")),
    image_url: Yup.string().required(t("validation.img")),
  })

  const articleSchema = Yup.object({
    title: Yup.string().required(t("validation.title")),
    slug: Yup.string()
      .nullable()
      .required(t("validation.slugReq"))
      .matches(slugReg, t("validation.slugMtch")),
    creator: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.creator")),
  })

  const pollSchema = Yup.object({
    text: Yup.string().required(t("validation.question")),
    cover_url: Yup.string().required(t("validation.img")),
    resultVisibility: Yup.boolean().nullable(),
    competitors: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string(),
          image_url: Yup.string(),
        })
      )
      .nullable()
      .required(t("validation.choices"))
      .min(2, t("validation.choices")),
  })

  const kickerSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    image_url: Yup.string().required(t("validation.img")),
    category: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.number(),
      })
      .nullable()
      .required(t("validation.category")),
    language: Yup.string(),
    slug: Yup.string()
      .nullable()
      .required(t("validation.slugReq"))
      .matches(slugReg, t("validation.slugMtch"))

  })

  const videoSchema = Yup.object({
    title: Yup.string().required(t("validation.title")),
    //file: Yup.file().required("يلزم اختيار فيديو"),
  })

  const urgentSchema = Yup.object({
    title: Yup.string().required(t("validation.title")),
    language: Yup.string()

  })


  return {
    loginSchema,
    addAdminSchema,
    editAdminSchema,
    editProfileSchema,
    categorySchema,
    homeSettingsSchema,
    footerSettingsSchema,
    urgentSchema,
    videoSchema,
    kickerSchema,
    pollSchema,
    articleSchema,
    creatorSchema,
    folderSchema,
    taskSchema,
    programSchema,
    stageSchema,
    postSchema,
    roleSchema,
    presenterSchema,
    episodeSchema
  }

}




export const stadiumSchema = Yup.object({
  name: Yup.string().required("يلزم ادخال الاسم"),
  slug: Yup.string()
    .nullable()
    .required("validation.slugReq")
    .matches(slugReg, "validation.slugMtch"),
  location: Yup.string().required("يلزم ادخال الموقع"),
  img: Yup.string().required("validation.img"),
  capacity: Yup.string().required("يلزم ادخال السعة"),
  floor: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("يلزم ادخال نوع الأرضية"),
  year: Yup.date().required("يلزم ادخال تاريخ الافتتاح"),
  raw_description: Yup.string().required("يلزم ادخال وصف"),
})
import { myAxios } from "./axios"

export function getSuppliers(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            name: data ? data.txt : null,
        },
    }
    return myAxios.get("/suppliers", config)
}


export function getSupplier(id) {
    return myAxios.get("/suppliers/" + id)
}

export function addSupplier(data) {
    return myAxios.post("/suppliers/create", data)
}

export function editSupplier(id, data) {
    return myAxios.put("/suppliers/" + id + "/update", data)
}

export function deleteSupplier(id) {
    return myAxios.delete("/suppliers/" + id + "/delete")
}

export function publishSupplier(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/suppliers/" + id + "/publish")
    } else {
        return myAxios.post("/suppliers/" + id + "/unpublish")
    }
}


export function importSuppliers(data) {
    return myAxios.post("/suppliers/import", data)
}

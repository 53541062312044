import { useEffect, useState } from "react"

export const useGetInstence = (api, instenceId, setInstence) => {
  const [loading, setLoading] = useState(true)

  const [error, setError] = useState("")

  const [success, setSuccess] = useState(false)

  const [payload, setPayload] = useState({})

  useEffect(() => {
    setLoading(true)
    api(instenceId)
      .then(res => {
        setInstence(res)
        setSuccess(true)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setError(err.response.data.errors[0])
        setLoading(false)
      })
  }, [payload])

  return {
    instenceSuccess: success,
    instenceLoading: loading,
    instenceError: error,
    setPayload,
  }
}



import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"
import SearchSelectBox from "../../../components/SearchSelectBox"
import SlugInput from "../../../components/SlugInput"
// import { getPresentersDropdown } from "../../../api/channels"
import ChooseImage from "../../../components/ChooseImage"
import { useTranslation } from "react-i18next"

export default function ChannelForm({validation}){
    const { t } = useTranslation()

    return(

        <Row>
            <Col md={6}>
                <FormGroup className="mb-3">
                    <Label>{t("forms.Name")}</Label>
                    <Input
                        name="name"
                        placeholder={t("forms.Name")}
                        type="text"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            validation.touched.name &&
                            validation.errors.name
                                ? true
                                : false
                        }
                    />
                    {validation.touched.name &&
                    validation.errors.name ? (
                        <FormFeedback type="invalid">
                            {validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </FormGroup>
            </Col>

        </Row>

    )
}
import { usePagination } from "../hooks/usePagination"
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"

import Select from "react-select"
import "./style.css"

function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default function SearchSelectBox(props) {
  const {t} = useTranslation()
  const [searchTxt, setSearchTxt] = useState("")
  const { response, setPayload, setMetaKey, meta } = usePagination(props.api, props.apiMeta,100)
  
  // useEffect(() => {
  //   if(searchTxt != null){
  //     processChange()
  //   }
  // }, [searchTxt])
  


 
  
  // const processChange =useCallback(debounce(() => setMetaKey('txt', searchTxt)),[]);

  const setSearch = useCallback(debounce((e) => setMetaKey('txt', e)),[])
  return (
    <>
      {response.data && (

        <Select
          isDisabled={props.disabled}
          placeholder={t("forms.Select")+" ..."}
          value={props.value ? {label: typeof response?.data?.[props.type].find(op => op.id===props.value)?.[props.label] === "object" ? response?.data?.[props.type].find(op => op.id===props.value)?.[props.label].en : response?.data?.[props.type].find(op => op.id===props.value)?.[props.label], value: props?.value} : null}
          onChange={props.onChange || (data => {
            props.setValue(data)
          })}
          isClearable={true}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          isMulti={props.isMulti}
          inputValue={searchTxt}
          onInputChange={(t)=>{
            setSearchTxt(t);
            setSearch(t)
          }}

          styles={{
// Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          options={response.data[props.type]
            .filter(c => c.id != props.exclude)
            .map(c => {
              return { label: typeof c[props.label] === "object"  ? c[props.label].ar : c[props.label], value: c.id }
            })}
          className={props.error ? "invalid" : ""}
        />
      )}
      {props.error ? (
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "80%",
            color: "#f46a6a",
          }}
        >
          {props.error}
        </div>
      ) : null}
    </>
  )
}

import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"


export default function TableHeaderHandler(props) {
  const navigate = useHistory()
  const { t } = useTranslation()
    const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  return (
    <Row className="mb-2" style={{ justifyContent: "space-between" }}>
      {/** Page Size Select */}
      <div style={{ marginBottom: "10px", width: "fit-content" }}>
        {props.setPerPage && (
          <select
            onChange={e => {
              props.setPage(1)
              props.setPerPage(e.target.value)
            }}
            className="form-select"
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {t("tables.Show")} {pageSize}
              </option>
            ))}
          </select>
        )}
      </div>

      <div style={{ width: "fit-content", paddingBottom: "10px" }} className={"d-flex"}>
          <div>{props.children}</div>

        {
            permissions.includes(props.permission) &&
            (
          <Button
            type="button"
            color="primary"
            className=" btn-add"
            onClick={() => {
              if (props.handleLinkClick) {
                props.handleLinkClick()
              } else {
                navigate.push(props.addUrl)
              }
            }}
          >
            <i className="mdi mdi-plus me-1" />
            {props.addText && t("btns." + props.addText)}

            {!props.addText && t("btns.Add")}
          </Button>

        )
        }



      </div>


    </Row>
  )
}

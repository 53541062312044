import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ChannelsTable from "./ChannelsTable"
import { useTranslation } from "react-i18next"

export default function ChannelsList() {

  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Channels")} />
        <ChannelsTable />
      </div>
    </div>
  )
}

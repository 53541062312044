import React, { useEffect } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { addAsset } from "../../../api/assets"
import ChooseImage from "../../../components/ChooseImage"
import FormButtonHandler from "../../../components/FormButtonHandler"
import SearchSelectBox from "../../../components/SearchSelectBox"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import FIELDS from "./CONSTANT"
import useAllowedFields from "../../../hooks/useAllowedFields"
import CustomForm from "../../../components/CustomForm"
import AssetForm from "./AssetForm"

const TYPES = {
    'select': null,
    'add-more': [],
    'checkbox': false
}

const initialValues = {}

FIELDS.forEach(f => {
    initialValues[f.key] = TYPES[f.type] ? TYPES[f.type] : "" 
})

const AddAsset = () => {
    const { t } = useTranslation()
    let navigate = useHistory()
    const { assetSchema } = useCustomValidation()
    const { setData, loading, error, success } = useResponse(addAsset, () => {
        navigate.push("/assets")
    })
    const getAllowedFields = useAllowedFields('Asset')


    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues,
        validationSchema: assetSchema,
        onSubmit: values => {
            let data = {
                ...values,
                name: {
                    en: values.nameEn,
                    ar: values.nameAr
                }
            }
            delete data.nameEn
            delete data.nameAr
            Object.keys(data).forEach(key => {
                const haveWritePerm = getAllowedFields().find(fi => fi.name == key)?.RoleField?.privileges?.includes('write')
                if(!haveWritePerm){
                    delete data[key]
                }
            })
            setData({ data, type: "add" })
        },
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("pages.Assets")}
                        breadcrumbItem={t("btns.Add") + " " + t("pages.Asset")}
                    />
                    
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        <AssetForm validation={validation} />

                                        <FormButtonHandler btnTxt="Add" loading={loading} />

                                        <ResultHandler
                                            error={error}
                                            success={success}
                                            successMsg={"Created Successfully!"}
                                        />
                                    </Form>
                               
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddAsset
import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Login"
import ChannelsList from "../pages/dashboard/channels/ChannelsList"
import ChannelForm from "../pages/dashboard/channels/ChannelForm";
import EditChannel from "../pages/dashboard/channels/EditChannel";
import AddChannel from "../pages/dashboard/channels/AddChannel";
import GenresList from "../pages/dashboard/genre/GenresList";
import EditGenre from "../pages/dashboard/genre/EditGenre";
import AddGenre from "../pages/dashboard/genre/AddGenre";
import EditSupplier from "../pages/dashboard/suppliers/EditSupplier";
import AddSupplier from "../pages/dashboard/suppliers/AddSupplier";
import SuppliersList from "../pages/dashboard/suppliers/SuppliersList";
import LanguagesList from "../pages/dashboard/languages/LanguagesList";
import EditLanguage from "../pages/dashboard/languages/EditLanguage";
import AddLanguage from "../pages/dashboard/languages/AddLanguage";
import AdminsList from "../pages/dashboard/admins/AdminsList";
import AddAdmin from "../pages/dashboard/admins/AddAdmin";
import EditAdmin from "../pages/dashboard/admins/EditAdmin";
import EditProfile from "../pages/dashboard/admins/EditProfile";
import RolesList from "../pages/dashboard/roles/RolesList";
import AddRole from "../pages/dashboard/roles/AddRole";
import EditRole from "../pages/dashboard/roles/EditRole";
import CategoriesList from "../pages/dashboard/categories/CategoriesList";
import AddCategory from "../pages/dashboard/categories/AddCategory";
import EditCategory from "../pages/dashboard/categories/EditCategory";
import AddAsset from "../pages/dashboard/assets/AddAsset";
import EditAsset from "../pages/dashboard/assets/EditAsset";
import AssetsList from "../pages/dashboard/assets/AssetList";
import EpisodesList from "../pages/dashboard/episodes/EpisodeList";
import EditEpisode from "../pages/dashboard/episodes/EditEpisode";
import AddEpisodes from "../pages/dashboard/episodes/AddEpisodes";
import AuditsList from "../pages/dashboard/audits/AuditsList";
import ViewAsset from "../pages/dashboard/assets/ViewAsset";


const adminRoutes = [
  {
    path: "/admins",
    component: AdminsList,
  },
  // {
  //   path: "/my-admins",
  //   component: MyAdminsList,
  // },
  {
    path: "/add-admin",
    component: AddAdmin,
  },
  {
    path: "/edit-admin/:id",
    component: EditAdmin,
  },
  // {
  //   path: "/get-admin/:id",
  // },
  { path: "/edit-profile", component: EditProfile },
  // {
  //   path: "/configration",
  //   component: Configration,
  // },
  // {
  //   path: "/all-notifications",
  // },
]

const authProtectedRoutes = [
  { path: "/channels", component: ChannelsList },
  { path: "/edit-channel/:id", component: EditChannel },
  { path: "/add-channel", component: AddChannel },

  { path: "/assets", component: AssetsList },
  { path: "/assets/:assetId/episodes", component: EpisodesList },
  { path: "/channel/:channelId/assets", component: AssetsList },
  { path: "/language/:languageId/assets", component: AssetsList },
  { path: "/genre/:genreId/assets", component: AssetsList },
  { path: "/category/:categoryId/assets", component: AssetsList },
  { path: "/supplier/:supplierId/assets", component: AssetsList },
  { path: "/episodes", component: EpisodesList },
  { path: "/edit-episode/:id", component: EditEpisode },
  { path: "/edit-asset/:id", component: EditAsset },
  { path: "/view-asset/:id", component: ViewAsset },
  { path: "/add-asset", component: AddAsset },
  { path: "/add-episode", component: AddEpisodes },
  { path: "/assets/:assetId/add-episode", component: AddEpisodes },

  { path: "/genres", component: GenresList },
  { path: "/edit-genre/:id", component: EditGenre },
  { path: "/categories", component: CategoriesList },
  { path: "/add-genre", component: AddGenre },
  { path: "/suppliers", component: SuppliersList },
  { path: "/edit-supplier/:id", component: EditSupplier },
  { path: "/add-supplier", component: AddSupplier },
  { path: "/languages", component: LanguagesList },
  { path: "/edit-language/:id", component: EditLanguage },
  { path: "/edit-category/:id", component: EditCategory },
  { path: "/add-category", component: AddCategory },
  { path: "/add-language", component: AddLanguage },
  { path: "/", exact: true, component: () => <Redirect to="/channels" /> },
  ...adminRoutes,
  { path: "/roles", component: RolesList, },
  {
    path: "/add-role",
    component: AddRole,
    // permission: PERMISSIONS.ROLE_CREATE,
  },
  {
    path: "/edit-role/:id",
    component: EditRole,
    // permission: PERMISSIONS.ROLE_EDIT,
  },
  {
    path: "/audits",
    component: AuditsList,
    // permission: PERMISSIONS.ROLE_EDIT,
  },
]

const publicRoutes = [
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }

import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EpisodesTable from "./EpisodesTable"
import { useTranslation } from "react-i18next"
import {useHistory, useParams} from "react-router-dom";

export default function EpisodesList() {

  const { t } = useTranslation()
    const params = useHistory()
    const { assetId} = useParams();
    const assetName = params?.location?.state?.assetName;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={(assetName?(assetName+" "):"")+t("pages.Episodes")} />
        <EpisodesTable assetId={assetId} />
      </div>
    </div>
  )
}

import {Card, CardBody, Input, Label} from "reactstrap";
import { t } from "i18next";
import moment from "moment";
import './query-builder.css'
import SearchSelectBox from "../SearchSelectBox";
import AddMoreInput from "../AddMoreInput";
import AddMoreInputV2 from "../AddMoreInputV2";
import React from "react";

const relations = [
    'AND',
    'OR'
]

const OPERATIONS = [
    { label: "Greater than", value: ">" },
    { label: "Less than", value: "<" },
    { label: "Equal", value: "=" },
    { label: "Not equal", value: "!=" },
    { label: "Greater than or equal", value: ">=" },
    { label: "Less than or equal", value: "<=" },
    { label: "Like", value: "like" },
    { label: "Not Like", value: "not_like" },
    { label: "In", value: "in" },
    { label: "Empty", value: "null" },

]

const CONDITION_BASE_SCHEMA = { type: "condition", field: "", value: "", operator: "" }

export default function QueryGroupV2({ group, onChangeFilter, level, fields, handleSubmit }) {


    function handleChangeCondition(key, val, condInx) {
        onChangeFilter({
            children: group.children.map((ch, chI) => {
                if (chI === condInx) {
                    return { ...ch, [key]: val, ...(key == 'field' ? { value: "", operator: "like", isJson: fields.find(f => f.key == val)?.isJson || false } : {}) }
                } else {
                    return { ...ch, operator: "like" }
                }
            })
        })

    }

    return (
        <div className={"group-row level" + level}>
            <div className="d-flex group-btns" style={{ width: "100%", justifyContent: "space-between" }}>
                <div className="d-flex">
                    <button className="relation-btn" onClick={() => onChangeFilter({ children: [...group.children, CONDITION_BASE_SCHEMA] })}>
                        + Filter
                    </button>
                </div>
            </div>
            <div className="d-flex group-children">
                {group.children.map((child, i) => {
                    const condition = child
                    const choosedFieldObj = fields?.find(f => condition.field === f?.key)
                    return <form onSubmit={(e)=>{
                        e.preventDefault()
                        handleSubmit()
                    }} key={i} className="d-flex condition-row">
                        <select
                            style={{ appearance: "auto", width: "50%" }}
                            value={condition.field}
                            className="form-control"
                            onChange={(e) => handleChangeCondition('field', e.target.value, i)}
                        >
                            <option>Choose field</option>
                            {fields.map(f => (
                                <option key={f.key} value={f.key}>{f.label}</option>
                            ))}
                        </select>
                        <div style={{width:"50%"}}>
                        {choosedFieldObj && (!choosedFieldObj.type || ['number', 'date', 'time','add-more','add-more-v2', 'textarea'].includes(choosedFieldObj.type)) && <>
                            <Input
                                name={choosedFieldObj.key}
                                placeholder={t("forms." + choosedFieldObj.label)}
                                type={choosedFieldObj.type || "text"}
                                className="form-control"
                                value={choosedFieldObj.type === 'date' ? moment(condition.value).format('YYYY-MM-DD') : (condition.value || "")}

                                onChange={(d) => handleChangeCondition('value', d.target.value, i)}
                            />
                        </>}
                        {choosedFieldObj && choosedFieldObj.type === 'checkbox' && <div className="d-flex" style={{ alignItems: "center" }}>
                            <input
                                name={choosedFieldObj.key}
                                placeholder={t("forms." + choosedFieldObj.label)}
                                type={'checkbox'}
                                value={choosedFieldObj.type === 'date' ? moment(condition.value).format('YYYY-MM-DD') : (condition.value || "")}

                                onChange={(e) => handleChangeCondition('value', e.target.value, i)}


                            />

                        </div>}
                        {choosedFieldObj && choosedFieldObj.type === 'select' &&
                                <SearchSelectBox
                                    name={choosedFieldObj.key}
                                    api={choosedFieldObj.api}
                                    type={choosedFieldObj.apiResKey}
                                    label={'name'}
                                    value={choosedFieldObj.type === 'date' ? moment(condition.value).format('YYYY-MM-DD') : (condition.value || "")}

                                    onChange={(d) => handleChangeCondition('value', d.value, i)}

                                />}
                        </div>


                        <div style={{ textAlign: "right" }}>
                            <span className="plus-btn" onClick={() => onChangeFilter({ children: group.children.filter((ch, chI) => chI != i) })}>
                                -
                            </span>
                        </div>
                    </form>
                })}
            </div>
        </div>
    )
}

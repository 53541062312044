import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

const DeleteModal = props => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="bx bx-error" style={{ fontSize: "9em" }} />
              <h2>{t("modals.Are you sure?")}</h2>
              <p>{t("modals.You won't be able to revert this!")}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={props.onDeleteClick}
              >
                {t("btns.Yes, delete it!")}
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg ms-2"
                onClick={props.onCloseClick}
              >
                {t("btns.Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default DeleteModal

import React from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function FormButtonHandler(props) {
  const { t } = useTranslation()

  return (
    <>
      {props.loading && (
        <Button className={props.size ? props.size : "btn-lg"} type="button">
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
          {t("btns.Loading")}
        </Button>
      )}
      {!props.loading && (
        <>
          {props.type && (
            <Button
              className={props.size ? props.size : "btn-lg"}
              color="primary"
              type={props.type ? props.type : "submit"}
              onClick={() => {
                props.handleClick()
              }}
            >
              {t("btns." + props.btnTxt)}
            </Button>
          )}
          {!props.type && (
            <Button
              className={props.size ? props.size : "btn-lg"}
              color="primary"
              type={"submit"}
            >
              {t("btns." + props.btnTxt)}
            </Button>
          )}
        </>
      )}
    </>
  )
}

import { myAxios } from "./axios"

export function getLanguages(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            title: data ? data.txt : null,
        },
    }
    return myAxios.get("/languages", config)
}


export function getLanguage(id) {
    return myAxios.get("/languages/" + id)
}

export function addLanguage(data) {
    return myAxios.post("/languages/create", data)
}

export function editLanguage(id, data) {
    return myAxios.put("/languages/" + id + "/update", data)
}

export function deleteLanguage(id) {
    return myAxios.delete("/languages/" + id + "/delete")
}

export function publishLanguage(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/languages/" + id + "/publish")
    } else {
        return myAxios.post("/languages/" + id + "/unpublish")
    }
}
import { myAxios } from "./axios"

export function getAdmins(payload, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      roleId: payload ? payload.type : null,
      email: payload ? payload.email : null,
    },
  }
  return myAxios.get("/admins", config)
}

export function getAdminsDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      username: data ? data.txt : null,
    },
  }
  return myAxios.get("/dropdown/admins", config)
}

export function getMyAdmins(payload, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      roleId: payload,
    },
  }
  return myAxios.get("/admin/my-admins", config)
}

export function addAdmin(data) {
  return myAxios.post("/admins/create", data)
}

export function editAdmin(id, data) {
  return myAxios.put("/admins/" + id + "/update", data)
}

export function getAdmin(id) {
  return myAxios.get("/admins/" + id)
}

export function deleteAdmin(id) {
  return myAxios.delete("/admins/" + id + "/delete")
}

export function changeAdminStatus(id) {
  return myAxios.post("/admins/" + id + "/change-status")
}

export function getProfile() {
  return myAxios.get("/me")
}

export function editProfile(data) {
  return myAxios.post("/admins/edit-profile", data)
}

export function getAllPermissions(data) {
  return myAxios.get("/permissions")
}
export function getAllFields(data) {
  return myAxios.get("/fields")
}

export function getAuthorPosts(id) {
  return myAxios.get("/admins/" + id + "/posts")
}

// notifications

export function getNotifications(_, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/notifications/my-notifications", config)
}

export function readNotification(id) {
  return myAxios.post("/notifications/" + id + "/read")
}

// audit

export function getHistoryLogs(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      operatorId: data ? data.operatorId?.value : null,
      model: data ? data.model : null,
      event: data ? data.event : null,
      search: data ? data.search : null,
      dates: data ? (data.to || data.from ? [data.from, data.to] : null) : null,
    },
  }
  return myAxios.get("/audits", config)
}

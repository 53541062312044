import { getAssets } from "../../../api/assets"


 const FIELDS = [
    {key: 'name', label: "Name"},
    {key: 'assetId', label: "Asset", type: 'select', apiResKey:"assets", api: getAssets},
    // {key: "seasonNum", label: "Season Number", width: 3, type: "number"},
    // {key: 'guest', label: "guest"},
    // {key: 'synopsis', label: "synopsis"},
    {key: 'duration', label: "Duration (Minutes)"},
    {key: 'qcNote', label: "qc Note"},
    {key: 'qcValid', label: "qc Valid", type: "checkbox"},
    {key: 'censorshipNote', label: "Censorship Note"},
    {key: 'censorshipValid', label: "Censorship Valid", type: "checkbox"},
     // broadcast code
    {key: 'broadcastCode', label: "Broadcast Code"},
     // episode num
    {key: 'episodeNum', label: "Episode Number", type: "number"},
    // {key: "keywords", label: "Keywords", type: "add-more"},
]


export default FIELDS
